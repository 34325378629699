import React from "react";
import { connect } from "react-redux";
import { MeClaimRepoActions } from "../repos/MeClaimRepo";
import "./style.css";
import { Tabs, Button, message } from "antd";
import { MeVehicleTypeTab } from "./tabs/me/MeVehicleTypeTab";
import { MeVehicleModelTab } from "./tabs/me/MeVehicleModelTab";
import { MeVehicleMakeTab } from "./tabs/me/MeVehicleMakeTab";
import { MeUserFuelGroupTab } from "./tabs/me/MeUserFuelGroupTab";
import { MeFuelTypeTab } from "./tabs/me/MeFuelTypeTab";
import { MeFuelGroupTab } from "./tabs/me/MeFuelGroupTab";
import { MeFuelGradeTab } from "./tabs/me/MeFuelGradeTab";
import { MeExtraExpenseRuleTab } from "./tabs/me/MeExtraExpenseRuleTab";
import { MeExpenseTypeTab } from "./tabs/me/MeExpenseTypeTab";
import { MeEngineCapacityTab } from "./tabs/me/MeEngineCapacityTab";
import { MeEntertainmentExpenseRuleTab } from "./tabs/me/MeEntertainmentExpenseRuleTab";

class MeClaimTabKeys {
	static M_VEHICLE_TYPE = "1";
	static M_VEHICLE_MODEL = "2";
	static M_VEHICLE_MAKE = "3";
	static M_FUEL_TYPE = "4";
	static M_FUEL_GRADE = "5";
	static M_ENGINE_CAPACITY = "6";
	static M_FUEL_GROUP = "7";
	static M_ENTERTAINMENT_EXPENSE_RULE = "8";
	static M_EXPENSE_TYPE = "9";
	static M_EXTRA_EXPENSE_RULE = "10";
	static M_USER_FUEL_GROUP = "11";
}

class MeClaim extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
	}

	async test() {
		const hide = message.loading("Loading");
		try {
			// 	await this.props.getAllVehicleTypes();
			// 	console.log("[√]	getAllVehicleTypes")
			// 	await this.props.getAllVehicleMake();
			// 	console.log("[√]	getAllVehicleMake")
			// 	await this.props.getAllVehicleModels();
			// 	console.log("[√]	getAllVehicleModels")
			// 	await this.props.getAllFuelTypes();
			// 	console.log("[√]	getAllFuelTypes")
			// 	await this.props.getAllFuelGrades();
			// 	console.log("[√]	getAllFuelGrades")
			// 	await this.props.getAllFuelGroups();
			// 	console.log("[√]	getAllFuelGroups")
			// 	await this.props.getAllUserFuelGroups();
			// 	console.log("[√]	getAllUserFuelGroups")
			// await this.props.getAllEntertainmentExpenseRules();
			await this.props.getAllExtraExpenseRules();
			// await this.props.getAllExpenseTypes();

			// await this.props.getAllMeUsers();

			console.log(this.props);
		} catch (err) {
			console.error(err);
		} finally {
			hide();
		}
	}

	async componentDidMount() {
		try {
			this.fetchTabData(MeClaimTabKeys.M_VEHICLE_TYPE);
		} catch (err) {}
	}

	async fetchTabData(key: string) {
		try {
			switch (key) {
				case MeClaimTabKeys.M_VEHICLE_TYPE: {
					await this.props.getAllVehicleTypes();
					break;
				}
				case MeClaimTabKeys.M_VEHICLE_MODEL: {
					await this.props.getAllVehicleMake();
					// Error
					await this.props.getAllVehicleModels();
					break;
				}
				case MeClaimTabKeys.M_VEHICLE_MAKE: {
					await this.props.getAllVehicleMake();
					break;
				}
				case MeClaimTabKeys.M_USER_FUEL_GROUP: {
					await this.props.getAllFuelGroups();
					await this.props.getAllUserFuelGroups();
					break;
				}
				case MeClaimTabKeys.M_FUEL_TYPE: {
					await this.props.getAllFuelTypes();
					break;
				}
				case MeClaimTabKeys.M_FUEL_GROUP: {
					await this.props.getAllFuelGroups();
					break;
				}
				case MeClaimTabKeys.M_FUEL_GRADE: {
					await this.props.getAllFuelTypes();
					await this.props.getAllFuelGrades();
					break;
				}
				case MeClaimTabKeys.M_EXTRA_EXPENSE_RULE: {
					await this.props.getAllExtraExpenseRules();
					await this.props.getAllMeUsers();
					break;
				}
				case MeClaimTabKeys.M_EXPENSE_TYPE: {
					await this.props.getAllExpenseTypes();
					break;
				}
				case MeClaimTabKeys.M_ENTERTAINMENT_EXPENSE_RULE: {
					await this.props.getAllEntertainmentExpenseRules();
					await this.props.getAllMeUsers();
					break;
				}
				case MeClaimTabKeys.M_ENGINE_CAPACITY: {
					break;
				}
			}
		} catch (err) {}
	}

	render() {
		return (
			<div>
				{/* <Button onClick={this.test.bind(this)}>Test</Button> */}
				<Tabs
					defaultActiveKey='1'
					type='card'
					size={"small"}
					onChange={this.fetchTabData.bind(this)}>
					<Tabs.TabPane tab='Vehical Type' key={MeClaimTabKeys.M_VEHICLE_TYPE}>
						<MeVehicleTypeTab
							sourceData={this.props.MeClaimRepo.vehicleTypes.reverse()}
							onDone={() => this.fetchTabData(MeClaimTabKeys.M_VEHICLE_TYPE)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab='Vehical Model' key={MeClaimTabKeys.M_VEHICLE_MODEL}>
						<MeVehicleModelTab
							sourceData={this.props.MeClaimRepo.vehicleModels.reverse()}
							onDone={() => this.fetchTabData(MeClaimTabKeys.M_VEHICLE_MODEL)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab='Vehical Make' key={MeClaimTabKeys.M_VEHICLE_MAKE}>
						<MeVehicleMakeTab
							sourceData={this.props.MeClaimRepo.vehicleMakes.reverse()}
							onDone={() => this.fetchTabData(MeClaimTabKeys.M_VEHICLE_MAKE)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab='User Fuel Group'
						key={MeClaimTabKeys.M_USER_FUEL_GROUP}>
						<MeUserFuelGroupTab
							sourceData={this.props.MeClaimRepo.userFuelGroups.reverse()}
							onDone={() => this.fetchTabData(MeClaimTabKeys.M_USER_FUEL_GROUP)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab='Fuel Type' key={MeClaimTabKeys.M_FUEL_TYPE}>
						<MeFuelTypeTab
							sourceData={this.props.MeClaimRepo.fuelTypes.reverse()}
							onDone={() => this.fetchTabData(MeClaimTabKeys.M_FUEL_TYPE)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane
						// disabled
						tab='Fuel Group'
						key={MeClaimTabKeys.M_FUEL_GROUP}>
						<MeFuelGroupTab
							sourceData={this.props.MeClaimRepo.fuelGroups.reverse()}
							onDone={() => this.fetchTabData(MeClaimTabKeys.M_FUEL_GROUP)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab='Fuel Grade' key={MeClaimTabKeys.M_FUEL_GRADE}>
						<MeFuelGradeTab
							sourceData={this.props.MeClaimRepo.fuelGrades.reverse()}
							onDone={() => this.fetchTabData(MeClaimTabKeys.M_FUEL_GRADE)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab='Expense Type' key={MeClaimTabKeys.M_EXPENSE_TYPE}>
						<MeExpenseTypeTab
							sourceData={this.props.MeClaimRepo.expenseTypes.reverse()}
							onDone={() => this.fetchTabData(MeClaimTabKeys.M_EXPENSE_TYPE)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab='Extra Expense Rule'
						key={MeClaimTabKeys.M_EXTRA_EXPENSE_RULE}>
						<MeExtraExpenseRuleTab
							sourceData={this.props.MeClaimRepo.extraExpenseRules.reverse()}
							onDone={() => this.fetchTabData(MeClaimTabKeys.M_EXTRA_EXPENSE_RULE)}
							/>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab='Entertainment Expense Rule'
						key={MeClaimTabKeys.M_ENTERTAINMENT_EXPENSE_RULE}>
						<MeEntertainmentExpenseRuleTab
							sourceData={this.props.MeClaimRepo.entertainmentExpenseRules.reverse()}
							onDone={() =>
								this.fetchTabData(MeClaimTabKeys.M_ENTERTAINMENT_EXPENSE_RULE)
							}
						/>
					</Tabs.TabPane>
					{/* <Tabs.TabPane tab='Engine Capacity' key={MeClaimTabKeys.M_ENGINE_CAPACITY}>
						<MeEngineCapacityTab
							sourceData={[]}
							onDone={() => this.fetchTabData(MeClaimTabKeys.M_ENGINE_CAPACITY)}
						/>
					</Tabs.TabPane> */}
				</Tabs>
			</div>
		);
	}
}

const withMeClaimRepo = connect(
	(state: any) => state,
	MeClaimRepoActions
)(MeClaim);
export default withMeClaimRepo;
