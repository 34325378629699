import React, { FunctionComponent } from "react";
import {
	Empty,
	Divider,
	Button,
	Typography,
	message,
	Row,
	Input,
	Avatar,
	Col,
	Select,
	Form,
	Table
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
	UploaderState,
	FileDataMapper,
	MultipleFileUploader,
	defaultImageExtensions
} from "../components";
import { withRouter } from "react-router";
import { lionUserManager } from "../repos/UserRepo";
import { UserRepoActions } from "../repos/UserRepo";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Elements } from "../components/Dashboard";

import { DashboardActions } from "../repos/DashboardRepo";
import "./style.css";
import { Tabs } from "antd";
import { AretworkCategoryTab } from "./tabs/artwork/ArtworkCategoryTab";
const { Option } = Select;
const { Column } = Table;
const { TabPane } = Tabs;
const moment = require("moment");
const { Paragraph, Text } = Typography;
const columns = [
	{
		title: "Name",
		dataIndex: "name",
		key: "name",
		render: (text: any) => <div>{text}</div>
	},
	{
		title: "Age",
		dataIndex: "age",
		key: "age"
	}
];

const data = [
	{
		key: "1",
		name: "John Brown",
		age: 32,
		address: "New York No. 1 Lake Park",
		tags: ["nice", "developer"]
	},
	{
		key: "2",
		name: "Jim Green",
		age: 42,
		address: "London No. 1 Lake Park",
		tags: ["loser"]
	},
	{
		key: "3",
		name: "Joe Black",
		age: 32,
		address: "Sidney No. 1 Lake Park",
		tags: ["cool", "teacher"]
	},
	{
		key: "1",
		name: "John Brown",
		age: 32,
		address: "New York No. 1 Lake Park",
		tags: ["nice", "developer"]
	},
	{
		key: "2",
		name: "Jim Green",
		age: 42,
		address: "London No. 1 Lake Park",
		tags: ["loser"]
	},
	{
		key: "3",
		name: "Joe Black",
		age: 32,
		address: "Sidney No. 1 Lake Park",
		tags: ["cool", "teacher"]
	}
];
const layout = {
	labelCol: {
		span: 8
	},
	wrapperCol: {
		span: 16
	}
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16
	}
};

class Artwork extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {};
	}

	async componentDidMount() {}

	getMainCategoryData() {
		return (
			<Row>
				<Col span={14}>
					<Table style={{ marginTop: 26, padding: 5 }} dataSource={data}>
						<Column
							title='No'
							key='index'
							render={(value, item, index) => index + 1}
						/>
						<Column
							title='Category Name '
							dataIndex='name'
							key='name'
							render={(value, item, index) => value}
						/>
						<Column title='address ' dataIndex='address' key='address' />

						<Column
							title='Action'
							key='index'
							render={(value, item, index) => (
								<span>
									<DeleteOutlined style={{ color: "#de2f38" }} />
									<EditOutlined style={{ color: "#de2f38", marginLeft: 10 }} />
								</span>
							)}
						/>
					</Table>
				</Col>
				<Col span={10}>
					<Form {...layout} name='control-hooks'>
						<Form.Item
							name='note'
							label='Note'
							rules={[
								{
									required: true
								}
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							name='gender'
							label='Gender'
							rules={[
								{
									required: true
								}
							]}>
							<Select
								placeholder='Select a option and change input text above'
								allowClear>
								<Option value='male'>male</Option>
								<Option value='female'>female</Option>
								<Option value='other'>other</Option>
							</Select>
						</Form.Item>
						<Form.Item
							noStyle
							shouldUpdate={(prevValues, currentValues) =>
								prevValues.gender !== currentValues.gender
							}>
							{({ getFieldValue }) =>
								getFieldValue("gender") === "other" ? (
									<Form.Item
										name='customizeGender'
										label='Customize Gender'
										rules={[
											{
												required: true
											}
										]}>
										<Input />
									</Form.Item>
								) : null
							}
						</Form.Item>
						<Form.Item {...tailLayout}>
							<Button type='primary' htmlType='submit'>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		);
	}

	render() {
		return (
			<div>
						<AretworkCategoryTab />
				{/* <Tabs defaultActiveKey='1' type='card' size={"small"}>
					<TabPane tab='Main Category' key='1'>
					</TabPane>
					<TabPane tab='Card Tab 2' key='2'></TabPane>
					<TabPane tab='Card Tab 3' key='3'>
						Content of card tab 3
					</TabPane>
					<TabPane tab='Card Tab 4' key='4'>
						Content of card tab 4
					</TabPane>
					<TabPane tab='Card Tab 5' key='5'>
						Content of card tab 5
					</TabPane>
				</Tabs> */}
				{/* <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                </Spin> */}
			</div>
		);
	}
}

const WrappedArtworkView = Artwork;
const withDashboardRepo = connect(
	(state: any) => state,
	DashboardActions
)(WrappedArtworkView);

export default withDashboardRepo;
