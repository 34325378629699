import React  from 'react';
import {   Button   ,Spin,     Select,  Table , Collapse ,message , Modal } from 'antd';
 
import { connect } from 'react-redux';
 
import {PlusCircleOutlined } from '@ant-design/icons';
import { UserManagementRepo } from '../../repos/UserManagementRepo';
import '../style.css';
import { Tabs  } from 'antd';

const { TabPane } = Tabs;
const { Option } = Select;
const { Column } = Table; 
 
const { Panel } = Collapse;
  
var authorityList : any = [];
var selectedAuthorityList : any = [];
var selectedIndex : any;
var selectedItemIndex : any;
class RoleManagement extends React.Component<any, any> { 
    constructor(props: any) {
        super(props); 
        this.state = { 
		  loading : false,
		  user_data:{},
		  moduleRoles:[],
		  isModalVisible : false,
		  authorities : [],
		  selectedAuthorities : []
        }
    }

    async componentDidMount() {
		  this.setState({
		loading : true, 
      },async ()=>{
		//await this.props.getUserDetaiById(JSON.parse(user_data).id); 
		await this.props.getRoleDetails();  
		this.setState({
		  loading : false,
		  moduleRoles:this.props.UserManagementRepo.roleDetails
		})
	  });  
	 ;
 
	   
     }
 
    getMasterDataView(){
        return null;
   
  }

//   addNewRole = (e : any , index : any) =>{
// 	 const { allPermitions , userPermitions } = this.props.UserManagementRepo.userDetail;
// 	  var selected_data =   allPermitions[index].roles[e];
// 	  var check = userPermitions[index].roles.filter((element : any) => {
// 		return element.id === selected_data.id;
// 	  }) 
// 	  if(check.length == 0){
// 		userPermitions[index].roles.push(selected_data);
// 		this.setState({
// 		  userPermitions :  userPermitions
// 	   })
// 	}
// 	else{
// 		message.error("You Cannot Assign This Role")
// 	}
	  
//   }

//   addNewAuthority = (e : any , index : any) =>{
// 	const { allPermitions , userPermitions } = this.props.UserManagementRepo.userDetail;
	
// 	var selected_data =  allPermitions[index].authorities[e];
// 	var check = userPermitions[index].authorities.filter((element : any) => {
// 		return element.id === selected_data.id;
// 	  }) 
// 	  if(check.length == 0){
// 		userPermitions[index].authorities.push(selected_data);
// 			this.setState({
// 			userPermitions :  userPermitions
// 		})
// 	}
// 	else{
// 		message.error("You Cannot Assign This Authority")
// 	}
// }

addNewAuthority = (e : any ) =>{
	var check =this.state.selectedAuthorities.filter((element : any) => {
		return element.id === authorityList[e].id;
	  }) 
	  if(check.length == 0){
		var array = [...this.state.selectedAuthorities];
		array.push(authorityList[e])
	     this.setState({
			selectedAuthorities : array
		})
	  }
	  else{
		  message.error("Already")
	  }
}

 

   

 
  removeAuthority = ( index: any , itemIndex:any)=>{
	var array = [...this.state.moduleRoles]; 
    array[index].authorities.splice(itemIndex, 1); 
    this.setState({moduleRoles: array});
 
  }

  viewAuthority = ( index: any , itemIndex:any)=>{
	selectedIndex = index;
	selectedItemIndex = itemIndex;
	var array = [...this.state.moduleRoles];  
	console.log(array[index].roles[itemIndex].authorities)
	authorityList = array[index].authorities;
	this.setState({
		authorities :  array[index].authorities,
	 	selectedAuthorities : array[index].roles[itemIndex].authorities,
		isModalVisible : true
	});
 
  }

  editUser = async() =>{
	  console.log(this.state.moduleRoles)
	this.setState({
		loading : true});
	   
	  var response = await this.props.editRoleData(this.state.moduleRoles);  
	  if (response.success) {
		window.location.reload();
	}
  }

  handleOk = () =>{
	console.log(selectedAuthorityList) 
	var array = [...this.state.moduleRoles]; 
	array[selectedIndex].roles[selectedItemIndex].authorities = this.state.selectedAuthorities
	this.setState({
		isModalVisible : false,
		moduleRoles:array
	  })
  }

  handleCancel = () =>{
	this.setState({
		authorities : [],
		isModalVisible : false
	});
  }

  callback = () =>{

  }

   render() {
	   const {   moduleRoles } = this.state;
	   const { UserManagementRepo } = this.props;
        return (
            <div>
              <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
			    
				{ moduleRoles &&  moduleRoles.map((data : any , index:any)=>{
                      return  <div style={{marginTop:20 , padding:20 , border: '1px solid #d6d6d6',borderRadius: '5px!important' }}>
					               <h3>{data.name}</h3>
								   <Collapse onChange={this.callback}>
										{data.roles.map((role:any , i : any)=>{
										  return <Panel header={role.name} key={i}>
											  <div style={{ display: "flex" }}>
											     <PlusCircleOutlined onClick={() => this.viewAuthority(index , i)}  style={{ marginLeft: "auto" }}/>
											  </div>
										      {role.authorities.length > 0 ? role.authorities.map((authority:any , i : any)=>{
											    return  <Button onClick={() => this.removeAuthority(index , i)}  style={{margin:1   }} type="dashed" danger>{authority.name}</Button>
										  }) : <h3>No authorities for this role</h3>}
										</Panel>})}
										 
									</Collapse>
					         </div>
				})}
               </Spin>
               {/* <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                </Spin> */}
				<Button onClick={() => this.editUser()}  style={{margin:1   }}  danger>SUBMIT</Button>
				<Modal title="Basic Modal" destroyOnClose={true} visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
				         <Select
							showSearch
							style={{ width: 400 }}
							placeholder="Search to Select"
							optionFilterProp="children"
							onChange={(e)=> this.addNewAuthority(e)}
						>
							{this.state.authorities.map((authority:any , i : any)=>{
								return <Option value={i}>{authority.name}</Option>
							})} 
						</Select>
                        <div style={{marginTop: 20}}>
						{this.state.selectedAuthorities.length > 0 && this.state.selectedAuthorities.map((authority:any , i : any)=>{
											    return  <Button    style={{margin:1   }} type="dashed" danger>{authority.name}</Button>
										  })}</div>
				</Modal>
            </div>
        )
    }
}


const WrappedUserManagement = RoleManagement;
const withUserManagementRepo = connect(((state: any) => state), UserManagementRepo)(RoleManagement);

export default withUserManagementRepo;

 