
const auth = btoa("OAUTH_DEMO_CLIENT_ID:hello")

// var _baseUrl = "https://lionapi-test.eimsky.com";
var _baseUrl = "https://lionapi.eimsky.com";

if (process.env.NODE_ENV == 'production') {
    // change this when going production
    _baseUrl = "https://lionapi.eimsky.com"
    //   _baseUrl = "https://lionapi-test.eimsky.com"
}

export const baseUrl = _baseUrl; 
export const imgBaseUrl = "https://lionapi.eimsky.com/clubMembership/downloadFile/"; 

export const headers = {
    "Content-Type": "application/javascript",
    "Authorization": "Basic " + auth,
    "cache-control": "no-cache",
}
 