import React, { FunctionComponent } from 'react';
import { Empty, Divider, Button, Typography,List, Row,Input, Avatar, Col, Badge ,Card, Drawer ,Spin, Form ,   Select,  Table } from 'antd';
 
import { connect } from 'react-redux';
 
import { UserAddOutlined ,DisconnectOutlined } from '@ant-design/icons';
import { ClaimRepo } from '../repos/ClaimRepo';
import './style.css';
import { Tabs  } from 'antd';
import ic_user from '../../images/ic_user.png'
const { Meta } = Card;
const { TabPane } = Tabs;
const { Option } = Select;
const { Column } = Table;
const moment = require('moment');
const { Paragraph, Text } = Typography;
 
const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text : any) => <div>{text}</div>,
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
  
 ];
  
  const data = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
      tags: ['nice', 'developer'],
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
      tags: ['loser'],
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
      tags: ['cool', 'teacher'],
    },
    {
        key: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
      },
      {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
      },
      {
        key: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sidney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
      },
  ];
  const layout = {
    labelCol: {
      span:5,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 5,
      span: 16,
    },
  };
  
  
 
class ClubMembershipClaim extends React.Component<any, any> { 
    constructor(props: any) {
        super(props); 
        this.state = {
          amount : "" , amountError : false , amountErrorMsg : "",
          role : "" , roleName : "" , roleError : false , roleErrorMsg : "",
		  loading : false,
		  visibleUser : false,
		  user:[]
        }
    }

    async componentDidMount() {
      this.setState({
        loading : true
      });
      await this.props.getActiveQuota();
      await this.props.getUserRoles(); 
      this.setState({
        loading : false
      });
     }

     
    onChangeAmount = (e: any) => {
     var _amount = e.target.value.replace(",", "");

      var regex = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
      var checkValue = _amount.match(regex);
      if (checkValue == null &&   _amount != "") {
        this.setState({
          amount: "", amountError : true , amountErrorMsg : "* Invalid Amount"
      });
         //message.error("Enter Valid Amount. For example: Rs .523.36");
         return;
    }
    else{
      this.setState({
        amount: _amount ,  amountError : false , amountErrorMsg : ""
    });
    }

   
 
  };

   

      changeUserRole = ( role : any) =>{
 
        var role_details = this.props.ClaimRepo.userRoles.find((o: { id: string; }) => o.id === role);
        this.setState({
          role : role_details.id , roleName : role_details.name , roleError : false , roleErrorMsg : ""
        });
     }

     submitAmount = async () => {
       if(this.state.role == ""){
        this.setState({
          role: "", roleError : true , roleErrorMsg : "* Please Select The Role"
      });
       }
       if(this.state.amount == ""){
        this.setState({
          amount: "", amountError : true , amountErrorMsg : "* Amount Can Not Be Blank"
      });
       }
       if(this.state.role != "" && this.state.amount != ""){
         var response =   await this.props.saveMemberTypeQuata(this.state.role , this.state.roleName , this.state.amount);
         if (response.success) {
             window.location.reload();
         }
       }
        
	 }
	 
	 viewUsers = async (data : any) =>{
		this.setState({
			loading : true
		})	 
		var data = await this.props.getUserList(data.roleId); 
		this.setState({
			user : data,
			visibleUser : true,
			loading : false
		})	 
	 }

	 onClose = () =>{
		this.setState({
			visibleUser : false
		})	 
	}
    
    getActiveQuataView(){ 
        return <Row>
        <Col span={14}>
        <Table pagination={{ pageSize: 10 }}  style={{ marginTop: 26, padding: 5 }} dataSource={this.props.ClaimRepo.activeQuata  } > 
                <Column title="*" key="index" render={(value, item, index) => ( index + 1  )}  />
                {/* <Column title="Role Id" dataIndex="roleId" key="roleId" render={(value, item , index) => value}/> */}
                <Column title="Role" dataIndex="roleName" key="roleName" />
                <Column title="Amount" dataIndex="maxLimit" key="maxLimit" render={(value, item , index) => ("Rs. " + (Math.round(value * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}/> 
                <Column title="Status" dataIndex="active" key="active"render={(value, item , index) => value == true ?  <Badge className="site-badge-count-109" count={"Active"} style={{ backgroundColor: '#52c41a', width:60 }} /> : <Badge className="site-badge-count-109" count={"Inactive"} style={{ backgroundColor: '#ebb446' , width:60 }} />} />
                                
                <Column
                            title="Action" 
                            render={(value, item, index) => (
							 	value.active &&  <span onClick={() => this.viewUsers(value)}>
                                    <UserAddOutlined style={{cursor:"pointer"}}  /> 
                                </span>
                            )}
                        />
            </Table>
           
        </Col>
        <Divider type="vertical" style={{height:450 , width:5, marginLeft:14}}></Divider>
        <Col span={9} >
          <h3 style={{margin:10}}>Create Quota</h3>
        <Form style={{marginTop:20}} {...layout}  name="control-hooks"  >
               
                <Form.Item
                     label="* User Role" 
                >
                  <Select onChange={this.changeUserRole} showSearch optionFilterProp="children">
                            {this.props.ClaimRepo.userRoles && this.props.ClaimRepo.userRoles.map((key: any) =>
                                <Select.Option value={key.id}>{key.name}</Select.Option>
                            )}
                        </Select>  
                        {this.state.roleError && <p style={{color:"#d9363e" , fontSize:9}}>{this.state.roleErrorMsg}</p>}
                </Form.Item>
                <Form.Item 
                    label="* Amount"
                    
                >
                   <Input onChange={this.onChangeAmount} value={this.state.amount} />
                  {this.state.amountError && <p style={{color:"#d9363e" , fontSize:9}}>{this.state.amountErrorMsg}</p>}
                </Form.Item>
                 
                <Form.Item {...tailLayout} >
                    <Button type="primary" onClick={this.submitAmount}>
                    Submit
                    </Button>
                    
                </Form.Item>
                </Form>

        </Col>
    </Row>
   
  }

  getData(){
    return <Row>
     
</Row>

}


   render() {

        return (
            <div>
              <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                <Tabs defaultActiveKey="1" type="card" size={'small'}>
                    <TabPane tab="User Types And Quota" key="1">
                    {this.getActiveQuataView()}
                    </TabPane>
                    {/* <TabPane tab="Card Tab 2" key="2">
                     {this.getData()}
                    </TabPane> */}
                     
                </Tabs>
               </Spin>
               {/* <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                </Spin> */}
				 <Drawer
				    width="600"
					title="Allocated Users"
					placement="right"
					closable={false}
					onClose={this.onClose}
					visible={this.state.visibleUser}
				>
					 <List
						dataSource={ this.state.user}
						bordered
						renderItem={(item : any )=> (
							<List.Item 
							actions={[
								 
							]}
							>
							<List.Item.Meta
								avatar={
								<Avatar src={ic_user} />
								}
								title={item.userDetails.firstName + " "  + item.userDetails.surname}
								description={item.userDetails.designationName}
							/>
							</List.Item>
						)}
						/>
				</Drawer>
            </div>
        )
    }
}


const WrappedClubMembershipClaimView = ClubMembershipClaim;
const withClubMembershipClaimRepo = connect(((state: any) => state), ClaimRepo)(ClubMembershipClaim);

export default withClubMembershipClaimRepo;

 