import React, { FunctionComponent } from 'react';
import { Empty, Divider, Button, Typography,message, Row,Input, Avatar, Col, Badge ,Radio, Modal ,Spin, Form ,   Select,  Table } from 'antd';
 
import { connect } from 'react-redux';
 
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { MedicalClaimRepo } from '../repos/MedicalClaimRepo';
import './style.css';
import { Tabs  } from 'antd';

const { TabPane } = Tabs;
const { Option } = Select;
const { Column } = Table;
const moment = require('moment');
const { Paragraph, Text } = Typography;
 
const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  
 
class MedicalClaim extends React.Component<any, any> { 
    constructor(props: any) {
        super(props); 
        this.state = {
            claimArea : "",
            claimAreaList : [],
            claimType : [],
            claimTypeName : "",
            amount : "" , amountError : false , amountErrorMsg : "",
          role : "" , roleName : "" , roleError : false , roleErrorMsg : "",
          loading : false
        }
    }
 

    async componentDidMount() {
        this.setState({ 
            loading : true
        });
        this.getDataList();
        
       }

       async getDataList(){
        await this.props.getAllClaimAreas(); 
        await this.props.claimTypeList();
		await this.props.getUserRoles();
		await this.props.getJobCategories();
		await this.props.getUserWiseCreditLimits()
        this.setState({
            claimAreaList : this.props.MedicalClaimRepo.claimArea,
            claimType : this.props.MedicalClaimRepo.claimType,
            loading : false
        });
       }
  
       
       onChangeAreaName = (e: any) => {
       var claimArea = e.target.value;
       this.setState({
        claimArea : claimArea
       });
         
  
     
   
    };

    addClaimTypeName= (e: any) => {
        var claimTypeName = e.target.value;
        this.setState({
            claimTypeName : claimTypeName
        });
          
   
      
    
     };
 
       
    submitClaimArea = async () => {
         
           var response =   await this.props.createClaimArea(this.state.claimArea);
           if (response.success) {
               this.getDataList();
           }
      
          
       }

       onChangeAmount = (e: any) => {
        var _amount = e.target.value.replace(",", "");
   
         var regex = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
         var checkValue = _amount.match(regex);
         if (checkValue == null &&   _amount != "") {
           this.setState({
             amount: "", amountError : true , amountErrorMsg : "* Invalid Amount"
         });
            //message.error("Enter Valid Amount. For example: Rs .523.36");
            return;
       }
       else{
         this.setState({
           amount: _amount ,  amountError : false , amountErrorMsg : ""
       });
       }
   
      
    
     };
	 jobCategoryName = (job_category_id : any) =>{
		this.setState({
			job_category : job_category_id
		});
	 }
      
   
         changeUserRole = ( role : any) =>{
    
           var role_details = this.props.ClaimRepo.userRoles.find((o: { id: string; }) => o.id === role);
           this.setState({
             role : role_details.id , roleName : role_details.name , roleError : false , roleErrorMsg : ""
           });
		}
		
		changeClaimType = ( claim_type_id : any) =>{
             this.setState({
				claimTypeId : claim_type_id
			});
		 }
   
        submitAmount = async () => {
           
          if(this.state.amount == ""){
           this.setState({
				amount: "", amountError : true , amountErrorMsg : "* Amount Can Not Be Blank"
			});
          }
          if(this.state.role != "" && this.state.amount != ""){
            var response =   await this.props.saveMemberTypeQuata(this.state.role , this.state.roleName , this.state.amount);
            if (response.success) {
                window.location.reload();
            }
          }
           
		}
		
		createQuota = () =>{ 
			var data = {"creditLimit":this.state.amount,
					"isActive":1,
					"name":"name",
					"claimType":{"id":this.state.claimTypeId},
					"jobCatId": 1 
					
				} 
			  this.props.createRoleWiseQuata(data).then((res:any) => {
				  if(res.success){
					window.location.reload();
				  }
				  else{
					  message.error("Something went wrong")
				  }
				   }
				)}


       submitClaimType = async () => {
          
        var response =   await this.props.createClaimType(this.state.claimTypeName);
        if (response.success) {
            this.getDataList();
            this.setState({
                claimTypeName : ""
            })
        }
   
       
    }
    getRoleWiserQuata(){
        return <Row>
        <Col span={14}>
        <Table pagination={{ pageSize: 4 }}  style={{ marginTop: 26, padding: 5 }} dataSource={this.props.MedicalClaimRepo.roleWiseCredit  } > 
                <Column title="*" key="index" render={(value, item, index) => ( index + 1  )}  />
                <Column title="Claim Type" dataIndex="claimType" key="claimType" render={(value, item , index) => value.name}/>
                <Column title="Role" dataIndex="roleName" key="roleName" />
                <Column title="Amount" dataIndex="creditLimit" key="creditLimit" render={(value, item , index) => ("Rs. " + (Math.round(value * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}/> 
                <Column title="Status" dataIndex="active" key="active"render={(value, item , index) => value == true ?  <Badge className="site-badge-count-109" count={"Active"} style={{ backgroundColor: '#52c41a', width:60 }} /> : <Badge className="site-badge-count-109" count={"Inactive"} style={{ backgroundColor: '#ebb446' , width:60 }} />} />
                                
                {/* <Column
                            title="Action"
                            key="index"
                            render={(value, item, index) => (
                                <span>
                                    <DeleteOutlined style={{color:"#de2f38"}}  />
                                   <EditOutlined style={{color:"#de2f38" , marginLeft: 10}}  /> 
                                </span>
                            )}
                        /> */}
            </Table>
           
        </Col>
        <Divider type="vertical" style={{height:350 , width:10, marginLeft:14}}></Divider>
        <Col span={9} >
          <h3 style={{margin:10}}>Create Quata</h3>
          <Form {...layout}  name="control-hooks">
               
                {/* <Form.Item
                     label="* User Role" 
                >
                  <Select onChange={this.jobCategoryName} showSearch optionFilterProp="children">
                            {this.props.MedicalClaimRepo.jobCategories && this.props.MedicalClaimRepo.jobCategories.map((key: any) =>
                                <Select.Option value={key.id}>{key.jobCategoryName}</Select.Option>
                            )}
                        </Select>  
                        {this.state.roleError && <p style={{color:"#d9363e" , fontSize:9}}>{this.state.roleErrorMsg}</p>}
                </Form.Item> */}
				<Form.Item
                     label="* Claim Type" 
                >
                  <Select onChange={this.changeClaimType} showSearch optionFilterProp="children">
                            {this.state.claimType && this.state.claimType.map((key: any) =>
                                <Select.Option value={key.id}>{key.name}</Select.Option>
                            )}
                        </Select>  
                        {this.state.roleError && <p style={{color:"#d9363e" , fontSize:9}}>{this.state.roleErrorMsg}</p>}
                </Form.Item>
                <Form.Item 
                    label="* Amount"
                    
                >
                   <Input onChange={this.onChangeAmount} value={this.state.amount} />
                  {this.state.amountError && <p style={{color:"#d9363e" , fontSize:9}}>{this.state.amountErrorMsg}</p>}
                </Form.Item>
                 
                <Form.Item {...tailLayout} >
                    <Button type="primary" onClick={this.createQuota}>
                    Submit
                    </Button>
                    
                </Form.Item>
                </Form>

        </Col>
    </Row>
    }

    
    getActiveQuataView(){
        return <Row>
        <Col span={14}>
        <Table pagination={{ pageSize: 4 }}  style={{ marginTop: 26, padding: 5 }} dataSource={this.state.claimType.reverse()   } > 
                <Column title="*" key="index" render={(value, item, index) => ( index + 1  )}  />
                {/* <Column title="Role Id" dataIndex="roleId" key="roleId" render={(value, item , index) => value}/> */}
                <Column title="Name" dataIndex="name" key="name" />
                                 
                {/* <Column
                            title="Action"
                            key="index"
                            render={(value, item, index) => (
                                <span>
                                    <DeleteOutlined style={{color:"#de2f38"}}  />
                                   <EditOutlined style={{color:"#de2f38" , marginLeft: 10}}  /> 
                                </span>
                            )}
                        /> */}
            </Table>
           
        </Col>
        <Divider type="vertical" style={{height:350 , width:10, marginLeft:14}}></Divider>
        <Col span={9} >
          <h3 style={{margin:10}}>Create Claim Type</h3>
        <Form {...layout}  name="control-hooks"   onFinish={this.submitClaimType} >
               
              
                <Form.Item 
                    label="Claim Type"
                    name="claimType"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Claim Type',
                      },
                    ]}
                    
                >
                   <Input value={this.state.clearType}  onChange={this.addClaimTypeName} />
                   
                </Form.Item>
                 
                <Form.Item {...tailLayout} >
                    <Button type="primary" htmlType="submit" >
                    Submit
                    </Button>
                    
                </Form.Item>
                </Form>

        </Col>
    </Row>
   
    }


    getOpdClaimArea(){
    return <Row>
    <Col span={14}>
    {this.state.claimAreaList != null &&  <Table pagination={{ pageSize: 10 }}  style={{ marginTop: 26, padding: 5 }} dataSource={this.state.claimAreaList.reverse()  } > 
            <Column title="*" key="index" render={(value, item, index) => ( index + 1  )}  />
            {/* <Column title="Role Id" dataIndex="roleId" key="roleId" render={(value, item , index) => value}/> */}
            <Column title="Claim Area Name" dataIndex="name" key="name" />
                             
            {/* <Column
                        title="Action"
                        key="index"
                        render={(value, item, index) => (
                            <span>
                                <DeleteOutlined style={{color:"#de2f38"}}  />
                               <EditOutlined style={{color:"#de2f38" , marginLeft: 10}}  /> 
                            </span>
                        )}
                    /> */}
        </Table>}
       
    </Col>
    <Divider type="vertical" style={{height:600 , width:10, marginLeft:14}}></Divider>
    <Col span={9} >
      <h3 style={{margin:20}}>Create OPD Claim Area</h3>

      <Form
            {...layout}
            name="basic" 
            onFinish={this.submitClaimArea} 
            >
            <Form.Item
                label="Claim Area "
                name="area"
                rules={[
                {
                    required: true,
                    message: 'Please input your claim area name',
                },
                ]}
            >
                <Input onChange={this.onChangeAreaName}/>
            </Form.Item>

           
 

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                Submit
                </Button>
            </Form.Item>
            </Form>
    

    </Col>
</Row>

}


   render() {

        return (
            <div>
                <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                <Tabs defaultActiveKey="1" type="card" size={'small'}>
                    <TabPane tab="Claim Type" key="1">
                       {this.getActiveQuataView()}
                    </TabPane>
                    <TabPane tab="OPD Claim Area" key="2">
                       {this.getOpdClaimArea()}
                    </TabPane>
                    <TabPane tab="Role Wise Active Quata" key="3">
                    {this.getRoleWiserQuata()}
                    </TabPane>
                    
                </Tabs>
               {/* <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                </Spin> */}
                </Spin>
            </div>
        )
    }
}


const WrappedMedicalClaimView = MedicalClaim;
const withMedicalClaimRepo = connect(((state: any) => state), MedicalClaimRepo)(MedicalClaim);

export default withMedicalClaimRepo;

 