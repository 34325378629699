import { texts } from "../texts.config";
import { lionUserManager } from './UserRepo'

interface RepoState {
    departments : []
}

export const Repo = (
    state: RepoState = {
		departments : []
	},
    action: { type: ClaimActionTypes, payload: any }) => {
        switch (action.type) {  
            case ClaimActionTypes.GET_ALL_DEPARTMENTS : {
                return { ...state, Departments : action.payload }
            }  
            
            default: return state;
        }
}

export const DepartmentManagementRepo = (dispatch: any) => ({
 
    GetAllDepartments: async () => {
        try {
            const res = await lionUserManager.authenticationManager.get('/user/admin/getDepartment');
            console.log("GetAllDepartments " , res.data);
            if (res.status == 200) {    
                return res.data
            } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },
})

export class ClaimActionTypes { ; 
    static GET_ALL_DEPARTMENTS = 'GET_ALL_DEPARTMENTS'; 

}