import React from "react";
import {
	FuelEngineCapacity,
	FuelClaimRepoActions,
	FuelFuelGrade,
	FuelFuelGradeRange
} from "../../../repos/FuelClaimRepo";
import { MasterdataEditTab } from "../../../components/MasterDataTab";
import { MasterDataFormMode, MasterDataForm } from "../../../components/Form";
import {
	Button,
	Input,
	Form,
	Select,
	Table,
	Alert,
	message,
	InputNumber,
	DatePicker,
	Col,
	Tooltip,
	Tag
} from "antd";
import { lionUserManager } from "../../../repos/UserRepo";
import { texts } from "../../../texts.config";
import { EditOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { connect } from "react-redux";

export class FuelFuelGradeRangeTab extends MasterdataEditTab<
	FuelFuelGradeRange
> {
	_onModeChange(mode: MasterDataFormMode) {
		super.clearItem();
	}

	async _validateDuplicates(rule: any, value: any) {
		if (this.props.sourceData) {
			if (this.props.sourceData.find(data => data.fuelGrade.id === value))
				throw new Error(`A range is already specified for the selected grade`);
		} else throw new Error("Fuel grade ranges not found");
	}

	render() {
		return (
			<>
				{this.state.item ? (
					<FuelFuelGradeFormWithRepo
						initialValue={this.state.item}
						validators={{}}
						onModeChange={this._onModeChange.bind(this)}
						onDone={async (data: FuelFuelGradeRange) => {
							let _hide = message.loading("Action in progress..", 0);
							try {
								const res = await lionUserManager.authenticationManager.post(
									`/fuelClaim/admin/editFuelGradeRange`,
									{
										...data,
										status: 1,
										startDate: data.startDate.format("YYYY-MM-DD"),
										endDate: data.endDate.format("YYYY-MM-DD")
									}
								);

								if (res.status == 200) {
									message.success("Done");
									super.onDone(data);
									super.clearItem();
								}
							} catch (err) {
								message.error(texts.DEFAULT_ERROR_MESSAGE);
							} finally {
								_hide();
							}
						}}
						key='fuel-fuel-grade-range-edit'
						mode={MasterDataFormMode.EDIT}
					/>
				) : (
					<FuelFuelGradeFormWithRepo
						initialValue={{
							id: "",
							name: "",
							startDate: moment(),
							endDate: moment(),
							max: 0,
							min: 0,
							fuelGrade: {
								id: "",
								name: "",
								unitPrice: 0,
								fuelType: { id: "", name: "", unitPrice: 0 }
							}
						}}
						validators={{
							check_duplicate: this._validateDuplicates.bind(this)
						}}
						onDone={async (data: FuelFuelGradeRange) => {
							let _hide = message.loading("Action in progress..", 0);
							try {
								const res = await lionUserManager.authenticationManager.post(
									"/fuelClaim/admin/createFuelGradeRange",
									{
										...data,
										status: 1,
										startDate: data.startDate.format("YYYY-MM-DD"),
										endDate: data.endDate.format("YYYY-MM-DD")
									}
								);

								if (res.status == 201) {
									message.success("Done");
									super.onDone(data);
								}
							} catch (err) {
								message.error(texts.DEFAULT_ERROR_MESSAGE);
							} finally {
								_hide();
							}
						}}
						key='fuel-fuel-grade-range-create'
						mode={MasterDataFormMode.CREATE}
					/>
				)}
				<Table dataSource={this.props.sourceData}>
					<Table.Column
						title='No'
						key='index'
						render={(value, item, index) => index + 1}
					/>

					<Table.Column
						title='Start Date'
						key='startDate'
						render={(value: FuelFuelGradeRange, item, index) =>
							moment(value.startDate).format("YYYY-MM-DD")
						}
					/>

					<Table.Column
						title='End Date'
						key='endDate'
						render={(value: FuelFuelGradeRange, item, index) =>
							moment(value.endDate).format("YYYY-MM-DD")
						}
					/>

					<Table.Column
						title='Fuel Grade'
						key='fgrade'
						render={(value: FuelFuelGradeRange, item, index) =>
							value.fuelGrade.name
						}
					/>

					<Table.Column
						title='Rate'
						key='rate'
						render={(value: FuelFuelGradeRange, item, index) => (
							<React.Fragment>
								<Col>
									<Tooltip title='Maximum Unit Price'>
										<Tag color='geekblue'>▲ {value.max}</Tag>
									</Tooltip>
								</Col>
								<Col>
									<Tooltip title='Minimum Unit Price'>
										<Tag color='purple'>▼ {value.min}</Tag>
									</Tooltip>
								</Col>
							</React.Fragment>
						)}
					/>

					<Table.Column
						title='Action'
						key='action'
						render={(value, item: FuelFuelGradeRange, index) => (
							<span>
								<EditOutlined
									style={{ color: "#de2f38", marginLeft: 10 }}
									onClick={() => this.setItem(item)}
								/>
							</span>
						)}
					/>
				</Table>
			</>
		);
	}
}

class FuelFuelGradeRangeForm extends MasterDataForm<FuelFuelGradeRange, any> {
	async _onDone() {
		if (this.formRef.current && (await this.validate())) {
			const dates: [Moment, Moment] = this.formRef.current.getFieldValue("dates");
			const { FuelClaimRepo } = this.props as any;
			const fGradeId = this.formRef.current.getFieldValue("fgrade");

			if (dates)
				super.onDone({
					id: this.props.initialValue.id,
					name: this.formRef.current.getFieldValue("name"),
					max: this.formRef.current.getFieldValue("max"),
					min: this.formRef.current.getFieldValue("min"),
					startDate: dates[0],
					endDate: dates[1],
					fuelGrade: FuelClaimRepo.fuelGrades.find(
						(value: FuelFuelGrade, i: number, s: any) => value.id == fGradeId
					)
				});
		}
	}

	render() {
		const { FuelClaimRepo } = this.props as any;
		return (
			<>
				{this.props.mode == MasterDataFormMode.EDIT && (
					<Alert
						message='Currently in Edit state.'
						type='info'
						closeText='Exit Now'
						onClose={() => super.changeMode(MasterDataFormMode.CREATE)}
					/>
				)}
				<Form
					// {...layout}
					ref={this.formRef}
					initialValues={{
						name: this.props.initialValue.name,
						fgrade: this.props.initialValue.fuelGrade.id,
						min: this.props.initialValue.min,
						max: this.props.initialValue.max,
						dates: this.props.mode == MasterDataFormMode.EDIT && [
							this.props.initialValue.startDate,
							this.props.initialValue.endDate
						]
					}}
					onFinish={this._onDone.bind(this)}>
					{/* <Form.Item
						name='name'
						label='Name'
						rules={[
							{
								required: true
							}
						]}>
						<Input />
					</Form.Item> */}
					<Form.Item
						name='fgrade'
						label='Fuel Grade'
						rules={[
							{
								validator: async (rule, value) => {
									if (this.props.validators["check_duplicate"])
										await this.props.validators["check_duplicate"](rule, value);
								}
							},
							{
								required: true,
								message: "Fuel grade is required"
							}
						]}>
						<Select
							allowClear
							disabled={
								FuelClaimRepo.fuelGrades.length < 1 ||
								this.props.mode == MasterDataFormMode.EDIT
							}>
							{FuelClaimRepo.fuelGrades.map((type: FuelFuelGrade) => (
								<Select.Option value={type.id}>{type.name}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name='min'
						label='Minimum Value'
						rules={[
							{
								required: true,
								message: "Minimum value is required"
							}
						]}>
						<InputNumber min={0} />
					</Form.Item>
					<Form.Item
						dependencies={["min"]}
						name='max'
						label='Maximum Value'
						rules={[
							({ getFieldValue }) => ({
								validator: async (rule, value) => {
									if (!value || getFieldValue("min") > value)
										throw new Error("Maximum value must be greater than minimum");
								}
							}),
							{
								required: true,
								message: "Maximum value is required"
							}
						]}>
						<InputNumber min={0} />
					</Form.Item>
					<Form.Item
						name='dates'
						label='Date Range'
						rules={[
							{
								required: true,
								message: "Date range is required"
							}
						]}>
						<DatePicker.RangePicker
							disabledDate={date => date && date < moment().endOf("day")}
						/>
					</Form.Item>

					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</>
		);
	}
}

// interface FuelExtraExpenseRule {
// 	id: string;
// 	name: string;
// 	expenseType: FuelExpenseType;
// 	amount: number;
// 	term: string;
// 	area: string;
// }

// interface FuelExpenseType {
// 	id: string;
// 	name: string;
// }

const FuelFuelGradeFormWithRepo = connect(
	(state: any) => state,
	FuelClaimRepoActions
)(FuelFuelGradeRangeForm);
