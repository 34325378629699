import React, { FunctionComponent } from 'react';
import { Empty, Divider, Button, Typography,message, Row,Input, Avatar, Col, Badge ,Radio, Modal ,Spin, Form ,   Select,  Table } from 'antd';
 
import { connect } from 'react-redux';
import { useHistory , withRouter } from "react-router-dom";
import { EditOutlined, FundViewOutlined } from '@ant-design/icons';
import { UserManagementRepo } from '../../repos/UserManagementRepo';
import '../style.css';
import { Tabs  } from 'antd';

const { TabPane } = Tabs;
const { Option } = Select;
const { Column } = Table;
const moment = require('moment');
 
  const layout = {
    labelCol: {
      span:5,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 5,
      span: 16,
    },
  };
  
  
 
class UserManagement extends React.Component<any, any> { 
    constructor(props: any) {
        super(props); 
        this.state = {
          amount : "" , amountError : false , amountErrorMsg : "",
          role : "" , roleName : "" , roleError : false , roleErrorMsg : "",
		  loading : false,
		  allUsers : []
        }
    }

    async componentDidMount() {
      this.setState({
        loading : true
      });
	  await this.props.getAllUsers();   
      this.setState({
		loading : false,
		allUsers: this.props.UserManagementRepo.userDetails
	  });
	  
	 
     }

     view = (data: any) =>{
		 localStorage.setItem('data', JSON.stringify(data));
		this.props.history.push({
			pathname: '/protected/user-view', 
			state: { detail: "Harith Madushan" }
		  })
	 }

	 
     search = (e : any) =>{ 
		const filteredData = this.props.UserManagementRepo.userDetails.filter((user:any, i : any) => { 
			  return user.userDetails.fullName && user.userDetails.fullName.toUpperCase().includes(e.target.value.toUpperCase())  
		}) 
		this.setState({
			loading : false,
			allUsers:  filteredData
		  });
	 }

	 searchByEmployeeNo = (e : any) =>{ 
	   const filteredData = this.props.UserManagementRepo.userDetails.filter((user:any, i : any) => { 
			 return user.userDetails.employeeNumber && user.userDetails.employeeNumber.includes(e.target.value)  
	   }) 
	   this.setState({
		   loading : false,
		   allUsers:  filteredData
		 });
	}

	searchByNic = (e : any) =>{ 
		const filteredData = this.props.UserManagementRepo.userDetails.filter((user:any, i : any) => { 
			  return user.userDetails.nicNumber && user.userDetails.nicNumber.includes(e.target.value)  
		}) 
		this.setState({
			loading : false,
			allUsers:  filteredData
		  });
	 }
	 searchByMobile = (e : any) =>{ 
		const filteredData = this.props.UserManagementRepo.userDetails.filter((user:any, i : any) => { 
			  return user.userDetails.staffPhone && user.userDetails.staffPhone.includes(e.target.value)  
		}) 
		this.setState({
			loading : false,
			allUsers:  filteredData
		  });
	 }
 
 
    
    getMasterDataView(){
        return <Row>
        <Col span={24}>
			<Row>
			    <Col span={5} style={{margin:10}}>
				    <Input onChange={(e) => this.searchByEmployeeNo(e)}  placeholder="Search By Employee No" /></Col>
			     <Col span={5} style={{margin:10}}>
				    <Input   onChange={(e) => this.search(e)}  placeholder="Search By Name" /></Col> 
				<Col span={5} style={{margin:10}}>
				    <Input onChange={(e) => this.searchByNic(e)}  placeholder="Search By NIC"  /></Col>
				<Col span={5} style={{margin:10}}>
				    <Input onChange={(e) => this.searchByMobile(e)}   placeholder="Search By Mobile" /></Col>
			</Row>
		
        <Table pagination={{ pageSize: 12 }}  style={{ marginTop: 15, padding: 5 }} dataSource={ this.state.allUsers } >  
                <Column title="Employee Number" dataIndex="userDetails" key="userDetails" render={(value, item , index) =>  value.employeeNumber}/>
				<Column title="Name" dataIndex="userDetails" key="userDetails" render={(value, item , index) => (value.firstName + " " + value.lastName)}/> 
				<Column title="NIC" dataIndex="userDetails" key="userDetails" render={(value, item , index) =>  value.nicNumber}/>  
				<Column title="EPF No" dataIndex="userDetails" key="userDetails" render={(value, item , index) =>  value.epfNo}/>  
				<Column title="Mobile" dataIndex="userDetails" key="userDetails" render={(value, item , index) =>  value.staffPhone}/>  
				<Column title="Designation" dataIndex="userDetails" key="userDetails" render={(value, item , index) =>  value.designationName}/>  
				  <Column
                            title="Action"
                            key="index"
                            render={(value, item, index) => (
                                <span>
                                    <FundViewOutlined onClick={() => this.view(value)} style={{color:"#de2f38"}}  />
                                   <EditOutlined style={{color:"#de2f38" , marginLeft: 10}}  /> 
                                </span>
                            )}
                        />
            </Table>
           
        </Col>
          
    </Row>
   
  }

  getData(){
    return <Row>
     
</Row>

}


   render() {

        return (
            <div>
              <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
			   
                {/* <Tabs defaultActiveKey="1" type="card" size={'small'}>
                    <TabPane tab="Loan Amount" key="1">
					<h5>View History</h5> */}
                      {this.getMasterDataView()}
                    {/* </TabPane>
                    
                     
                </Tabs> */}
               </Spin>
               {/* <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                </Spin> */}
            </div>
        )
    }
}


const WrappedUserManagement = UserManagement;
const withUserManagementRepo = connect(((state: any) => state), UserManagementRepo)(UserManagement);

export default withRouter(withUserManagementRepo);

 