import React, { Component } from "react";
import { Menu, Row, Col, Popover, Button, Switch as Toggler, Avatar, Input } from 'antd';
import { ShowTheLocationWithRouter, ContentPresenter, DefaultAvatar, SimpleHeader } from './components'
import { Artwork , MedicalClaim, ClubMembershipClaim , FuelClaim, PettyCashClaim,MeClaim , StaffLoan , UserManagement , UserView , RoleManagement} from './pages'
import { logOutAsLionUser, lionUserManager, userManager } from './repos/UserRepo'
import DefaultLayout from './layouts/DefaultLayout';
import {
    withRouter,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";
import './App.css';
import { RouteConfig } from "./route.config";
import {
    AppstoreOutlined,
    UnorderedListOutlined,
	AuditOutlined,PayCircleOutlined,
	UserAddOutlined ,
	UserOutlined,
    BankOutlined
} from '@ant-design/icons';
import './ProtectedApp.css';
import logo from '../images/logo.png'
import { LionUser } from "user-manager";
import { METHODS } from "http";
import DepartmentManagement from "./pages/DepartmentManagement/DepartmentManagement";
const { SubMenu } = Menu;

class ProtectedApp extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { collapsed: true, };
    }

    toggle() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        return (
            <DefaultLayout
                header={<ProtectedAppHeader />}
                sidebar={<ProtectedAppSidebar collapsed={this.state.collapsed} toggle={this.toggle.bind(this)} />}
                isSidebarCollapsed={this.state.collapsed}>
                {/* <FilterHeaderStrip /> */}
                <div className="breadcrumbs-container">
                    <ShowTheLocationWithRouter />
                </div>
                <Switch>
                    <Route exact path={RouteConfig.PROTECTED_ARTWORK}>
                        <ContentPresenter><div><Artwork /></div></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_MEDICAL_CLAIM}>
                        <ContentPresenter><div><MedicalClaim /></div></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_CLUB_MEMBERSHIP_CLAIM}>
                        <ContentPresenter><div><ClubMembershipClaim /></div></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_FUEL_CLAIM}>
                        <ContentPresenter><div><FuelClaim /></div></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_PETTY_CASH_CLAIM}>
                        <ContentPresenter><div><PettyCashClaim /></div></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_ME_CLAIM}>
                        <ContentPresenter><div><MeClaim /></div></ContentPresenter>
                    </Route>
					<Route exact path={RouteConfig.PROTECTED_STAFF_LOAN}>
                        <ContentPresenter><div><StaffLoan /></div></ContentPresenter>
                    </Route>
					<Route exact path={RouteConfig.PROTECTED_USER_MANAGEMENT}>
                        <ContentPresenter><div><UserManagement /></div></ContentPresenter>
                    </Route>
					<Route exact path={RouteConfig.PROTECTED_USER_VIEW}>
                        <ContentPresenter><div><UserView /></div></ContentPresenter>
                    </Route>
					<Route exact path={RouteConfig.PROTECTED_ROLE_MANAGEMENT}>
                        <ContentPresenter><div><RoleManagement /></div></ContentPresenter>
                    </Route>
					<Route exact path={RouteConfig.PROTECTED_DEPARTMENT_MANAGEMENT}>
                        <ContentPresenter><div><DepartmentManagement /></div></ContentPresenter>
                    </Route>
					
                    
                    <Route path={RouteConfig.PROTECTED_ROOT}>
                        <Redirect to={{ pathname: RouteConfig.PROTECTED_ARTWORK }} />
                    </Route>
                </Switch>
            </DefaultLayout>
        );
    }
}

class FilterHeaderStrip extends Component<any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Col className="header-strip-container">
                <Row>
                    <Input />
                </Row>
            </Col>
        )
    }
}

class ProtectedAppHeader extends Component<any> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount() { }

    render() {
        return (
            <Row justify="end" align="middle" className="main-header-container" gutter={35}>
                <Col className="main-header-notification-icon">
                    {/* <Popover content={data && <NotificationContainer data={data} />} placement="topLeft" trigger="click">
                        <Badge count={data && data.length}>
                            <div className="header-icon"><BellFilled /></div>
                        </Badge>
                    </Popover> */}
                </Col>
                <Col>
                    <Popover content={userManager.user && <UserPopover user={userManager.user} />} placement="topLeft" trigger="click">
                        <div><DefaultAvatar user={lionUserManager.user} /></div>
                    </Popover>
                </Col>
            </Row>

        );
    }
}

export class UserPopover extends Component<{ user: LionUser }>{
    render() {
        return (
            <Row className="user-popover-container" gutter={[10, 10]} align="middle" justify="center">
                {lionUserManager && <Col>
                    <Row align="middle" justify="center" className="user-popover-avatar-name">
                        <Avatar src={lionUserManager.user.data.User.imagePaths} size={70} />
                    </Row>
                    <Row style={{ marginTop: 6 }} align="middle" justify="center" className="user-popover-avatar-role" gutter={[10, 10]}>
                        {lionUserManager.user.data.User.firstName + " " + lionUserManager.user.data.User.lastName}
                    </Row>
                    <Row style={{ marginTop: 6 }} align="middle" justify="center" className="user-popover-avatar-role" gutter={[10, 10]}>
                        {lionUserManager.user.data.User.designationName}
                    </Row>
                    <Row style={{ marginTop: 1 }} align="middle" justify="center" className="user-popover-avatar-email" gutter={[10, 10]}>
                        {lionUserManager.user.data.User.email}
                    </Row>
                    <Row style={{ marginTop: 6 }} align="middle" justify="center">
                        <Button onClick={logOutAsLionUser} shape="round" className="user-popover-user-sign-out-button">Sign Out</Button>
                    </Row>
                </Col>}
            </Row>
        );
    }
}

class NotificationContainer extends Component<any>{
    render() {
        return (
            <Row style={{ width: 400 }}>
                <Col span={24}>
                    <SimpleHeader title="Notifications" />
                </Col>
                <Col className="notification-container">{

                }</Col>
            </Row>
        );
    }
}

class ProtectedAppSidebar extends Component<{ collapsed: boolean, toggle: () => void } | any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div className="sidebar">
                    <div className="flex-center logo"  >
                        <img src={logo} style={{ width: '100%' }}></img>
                    </div>

                    <Menu mode="inline" defaultSelectedKeys={['0']}>
                        <Menu.Item key="0">
                            <Link to={RouteConfig.PROTECTED_ARTWORK}>
                                <AppstoreOutlined />
                                <span>Art Work</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="1">
                            <Link to={RouteConfig.PROTECTED_MEDICAL_CLAIM}>
                                <AuditOutlined />
                                <span>Medical Claims</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Link to={RouteConfig.PROTECTED_CLUB_MEMBERSHIP_CLAIM}>
                            <PayCircleOutlined />
                                <span>Club Membership</span>
                            </Link>
                        </Menu.Item> 
                        <Menu.Item key="3">
                            <Link to={RouteConfig.PROTECTED_FUEL_CLAIM}>
                            <UnorderedListOutlined />
                                <span>Fuel Membership</span>
                            </Link>
                        </Menu.Item> 
                         
                        <Menu.Item key="4">
                            <Link to={RouteConfig.PROTECTED_PETTY_CASH_CLAIM}>
                                <AppstoreOutlined />
                                <span>Petty Cash</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="5">
                            <Link to={RouteConfig.PROTECTED_ME_CLAIM}>
                                <AppstoreOutlined />
                                <span>ME Claim</span>
                            </Link>
                        </Menu.Item>
						<Menu.Item key="6">
                            <Link to={RouteConfig.PROTECTED_STAFF_LOAN}>
                                <AppstoreOutlined />
                                <span>Staff Loan</span>
                            </Link>
                        </Menu.Item>
						 
						<SubMenu  key="sub3" title={<span><UserAddOutlined /><span>User Management</span></span>}>
						    
							<Menu.Item key="7">
								<Link to={RouteConfig.PROTECTED_USER_MANAGEMENT}>
									<UserOutlined />
									<span>Users</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="8">
								<Link to={RouteConfig.PROTECTED_ROLE_MANAGEMENT}>
									<AppstoreOutlined />
									<span>Roles</span>
								</Link>
							</Menu.Item>
                            <Menu.Item key="9">
								<Link to={RouteConfig.PROTECTED_DEPARTMENT_MANAGEMENT}>
									<BankOutlined/>
									<span>Departments</span>
								</Link>
							</Menu.Item>
						</SubMenu>
 
                    </Menu>
                </div>

                <span className="sidebar-toggle-button">
                    <Toggler size="small" defaultChecked={this.props.collapsed} onChange={this.props.toggle} />
                </span>
            </React.Fragment>
        )
    }
}

// const ProtectedAppHeaderWithUser = connect((state => state), UserActionTypes)(ProtectedAppHeader);

export default withRouter(ProtectedApp);