import { texts } from "../texts.config";
import { lionUserManager } from './UserRepo'

export const Repo = (state = { loanDetails : null , userRoles : null  } , action: { type: ClaimActionTypes, payload: any }) => {
    switch (action.type) {  
        case ClaimActionTypes.LOAN_DETAILS : {
            return { ...state, loanDetails : action.payload }
        }   
        
        case ClaimActionTypes.GET_USER_ROLES : {
            return { ...state, userRoles : action.payload }
        }  
         
        default: return state;
    }
}

export const StaffLoanRepo = (dispatch: any) => ({
 
    getLoanDetails: async () => {
        try { 
            const res = await lionUserManager.authenticationManager.get('/staffLoan/Admin/getLoanDetails');
            console.log(res)
            if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.LOAN_DETAILS,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },


    getUserRoles: async () => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.get('/user/role/getByModule/4');
        
            if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.GET_USER_ROLES,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },


    saveMemberTypeQuata: async (roleId : any , roleName : any , amount : any) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.post('/clubMembership/admin/memberTypeQuota' ,  {
                "roleId": roleId,
                "roleName": roleName,
                "maxLimit": amount
            });
            console.log(res); 
            if (res.status == 201) { 
                  response = {
                      "success" : true,
                      "id" : res.data.Id
                  }
                 
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                 response = {
                    "success" : false 
                }
                return response;
            }
        } catch (err) {
            return false;
            throw err;
        }
    },
  
	CreateNewQuata: async (data : any ) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.post('/staffLoan/Admin/createHistoryOfLoanDetails' ,  data);
            console.log(res); 
            if (res.status == 201) { 
                  response = {
                      "success" : true,
                       
                  }
                 
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                 response = {
                    "success" : false 
                }
                return response;
            }
        } catch (err) {
            return false;
            throw err;
        }
    },
 
 
})

export class ClaimActionTypes {
    static LOAN_DETAILS = 'LOAN_DETAILS'; 
    static GET_USER_ROLES = 'GET_USER_ROLES'; 

}