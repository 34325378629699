import { texts } from "../texts.config";
import { lionUserManager } from './UserRepo'

export const Repo = (state = {fuelGrades : null , userDetails : null , userRoles : null , roleDetails:null } , action: { type: ClaimActionTypes, payload: any }) => {
    switch (action.type) {  
        case ClaimActionTypes.USER_DETAILS : {
            return { ...state, userDetails : action.payload }
        }   
        
        case ClaimActionTypes.USER_DETAIL : {
            return { ...state, userDetail : action.payload }
		}  
		case ClaimActionTypes.ROLE_DETAIL : {
            return { ...state, roleDetails : action.payload }
		} 
		
		case ClaimActionTypes.GET_FUEL_FUEL_GRADES : {
            return { ...state, fuelGrades : action.payload }
        } 
		 
		
        default: return state;
    }
}

export const UserManagementRepo = (dispatch: any) => ({
 
    getAllUsers: async () => { 
        try { 
			const res = await lionUserManager.authenticationManager.get('/user/get');
			console.log(res)
            if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.USER_DETAILS,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    }, 

    getUserDetaiById: async (id:any) => {
		try {
            var response;
            const res = await lionUserManager.authenticationManager.get('/user/admin/getEdit/' + id);
			 
            if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.USER_DETAIL,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
	},
	
	getRoleDetails: async (id:any) => {
		try { 
            const res = await lionUserManager.authenticationManager.get('/user/admin/getRoleEdit');
			 console.log(res)
            if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.ROLE_DETAIL,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },


    editUserData: async (data : any) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.post('/user/admin/doEdit' ,  data);
             if (res.status == 200) { 
				response = {
                    "success" : true 
                }
                 
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                 response = {
                    "success" : false 
                }
                return response;
            }
        } catch (err) {
            return false;
            throw err;
        }
	},
	
	editRoleData: async (data : any) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.post('/user/admin/doRoleEdit' ,  data);
             if (res.status == 200) { 
				response = {
                    "success" : true 
                }
                 
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                 response = {
                    "success" : false 
                }
                return response;
            }
        } catch (err) {
            return false;
            throw err;
        }
    },
  
	CreateNewQuata: async (data : any ) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.post('/staffLoan/Admin/createHistoryOfLoanDetails' ,  data);
            console.log(res); 
            if (res.status == 201) { 
                  response = {
                      "success" : true,
                       
                  }
                 
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                 response = {
                    "success" : false 
                }
                return response;
            }
        } catch (err) {
            return false;
            throw err;
        }
	},
	

	getAllFuelGrades: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/fuelClaim/vehicle/AllListFuelGrade"
			);
			console.log(res)
			if (res.status == 200) {
				dispatch({
					type: ClaimActionTypes.GET_FUEL_FUEL_GRADES,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},


	AddFualGrade : async (data : any ) => {
        try { 
            var response;
            const res = await lionUserManager.authenticationManager.post('/fuelClaim/EmplyeeFuelGrade/Create' ,  data);
            console.log(res); 
            if (res.status == 201) { 
                  response = {
                      "success" : true,
                       
                  }
                 
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                 response = {
                    "success" : false 
                }
                return response;
            }
        } catch (err) {
            return false;
            throw err;
        }
	}, 
	getFuelGradeOfUser: async (data:any) => {
		try {
			const res = await lionUserManager.authenticationManager.post(
				"/fuelClaim/EmplyeeFuelGrade/GetEligibleFuelGrads" , data
			);
			console.log("res" , res)
			if (res.status == 200) {
				 return res;
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},
	//http://localhost:8600/EmplyeeFuelGrade/InactiveEmployeeFuelGrade/15
	removeFuelGradeOfUser: async (id:any) => {
		try {
			const res = await lionUserManager.authenticationManager.post(
				"/fuelClaim/EmplyeeFuelGrade/InactiveEmployeeFuelGrade/" + id , {}
			);
			 console.log("res" , res)
			if (res.status == 200) {
				 return true;
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

 
 
})

export class ClaimActionTypes {
    static USER_DETAILS = 'USER_DETAILS'; 
	static USER_DETAIL = 'USER_DETAIL'; 
	static ROLE_DETAIL = 'ROLE_DETAIL'; 
	static GET_FUEL_FUEL_GRADES = "GET_FUEL_FUEL_GRADES";

}