import { texts } from "../texts.config";
import { lionUserManager } from "./UserRepo";
import moment, { Moment } from "moment";
import { async } from "q";
import { reduce } from "traverse";

// export interface IMeRepo extends IMeClaimRepoActions {
// 	MeRepo: MeRepoState;
// }

interface MeRepoState {
	vehicleTypes: MeVehicalType[];
	vehicleMakes: MeVehicalMake[];
	vehicleModels: MeVehicalModel[];
	fuelTypes: MeFuelType[];
	fuelGrades: MeFuelGrade[];
	fuelGroups: MeFuelGroup[];
	userFuelGroups: MeUserFuelGroup[];
	entertainmentExpenseRules: MeEntertainmentExpenseRule[];
	extraExpenseRules: MeExtraExpenseRule[];
	expenseTypes: MeExpenseType[];
	users: MeUser[];
}

const MeRepoDefaultState: MeRepoState = {
	vehicleTypes: [],
	vehicleMakes: [],
	vehicleModels: [],
	fuelTypes: [],
	fuelGrades: [],
	fuelGroups: [],
	userFuelGroups: [],
	entertainmentExpenseRules: [],
	extraExpenseRules: [],
	expenseTypes: [],
	users: []
};

export const Repo = (
	state: MeRepoState = MeRepoDefaultState,
	action: { type: MeRepoActionTypes; payload: any }
): MeRepoState => {
	switch (action.type) {
		case MeRepoActionTypes.GET_ME_VEHICLE_TYPES: {
			return { ...state, vehicleTypes: action.payload };
		}
		case MeRepoActionTypes.GET_ME_VEHICLE_MAKES: {
			return { ...state, vehicleMakes: action.payload };
		}
		case MeRepoActionTypes.GET_ME_VEHICLE_MODELS: {
			return { ...state, vehicleModels: action.payload };
		}
		case MeRepoActionTypes.GET_ME_FUEL_TYPES: {
			return { ...state, fuelTypes: action.payload };
		}
		case MeRepoActionTypes.GET_ME_FUEL_GRADES: {
			return { ...state, fuelGrades: action.payload };
		}
		case MeRepoActionTypes.GET_ME_FUEL_GROUPS: {
			return { ...state, fuelGroups: action.payload };
		}
		case MeRepoActionTypes.GET_ME_USER_FUEL_GROUPS: {
			return { ...state, userFuelGroups: action.payload };
		}
		case MeRepoActionTypes.GET_ME_ENTERTAINMENT_EXPENSE_RULE: {
			return { ...state, entertainmentExpenseRules: action.payload };
		}
		case MeRepoActionTypes.GET_ME_EXPENSE_TYPES: {
			return { ...state, expenseTypes: action.payload };
		}
		case MeRepoActionTypes.GET_ME_EXTRA_EXPENSE_RULE: {
			return { ...state, extraExpenseRules: action.payload };
		}
		case MeRepoActionTypes.GET_ME_USER_ROLES: {
			return { ...state, users: action.payload };
		}
		default:
			return state;
	}
};

export interface IMeClaimRepoActions {
	getAllVehicleTypes: () => Promise<any>;
	getAllVehicleMake: () => Promise<any>;
	getAllVehicleModels: () => Promise<any>;
	getAllFuelTypes: () => Promise<any>;
	getAllFuelGrades: () => Promise<any>;
	getAllFuelGroups: () => Promise<any>;
	getAllUserFuelGroups: () => Promise<any>;
	getAllEntertainmentExpenseRules: () => Promise<any>;
	getAllExtraExpenseRules: () => Promise<any>;
	getAllExpenseTypes: () => Promise<any>;
	getAllMeUsers: () => Promise<any>;
}

export const MeClaimRepoActions = (dispatch: any): IMeClaimRepoActions => ({
	getAllVehicleTypes: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/meClaim/FuelRegister/GetAllVehicalTypes"
			);
			if (res.status == 200) {
				dispatch({
					type: MeRepoActionTypes.GET_ME_VEHICLE_TYPES,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllVehicleMake: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/meClaim/FuelRegister/GetAllVehicalMake"
			);
			if (res.status == 200) {
				dispatch({
					type: MeRepoActionTypes.GET_ME_VEHICLE_MAKES,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllVehicleModels: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/meClaim/FuelRegister/GetAllVehicalModels"
			);
			console.log({res})
			if (res.status == 200) {
				dispatch({
					type: MeRepoActionTypes.GET_ME_VEHICLE_MODELS,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllFuelTypes: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/meClaim/FuelRegister/GetAllFuelType"
			);
			if (res.status == 200) {
				dispatch({
					type: MeRepoActionTypes.GET_ME_FUEL_TYPES,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllFuelGrades: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/meClaim/FuelRegister/GetAllFuelGrade"
			);
			if (res.status == 200) {
				dispatch({
					type: MeRepoActionTypes.GET_ME_FUEL_GRADES,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllFuelGroups: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/meClaim/FuelGroup/GetAllFuelGroupWithReference"
			);
			console.log(res.data);

			if (res.status == 200) {
				dispatch({
					type: MeRepoActionTypes.GET_ME_FUEL_GROUPS,
					payload: res.data.map((data: any) => ({
						...data,
						createdOn: moment(data.createdOn)
					}))
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllUserFuelGroups: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/meClaim/FuelGroup/GetAllUserFuelGroup"
			);
			console.log(res.data);
			if (res.status == 200) {
				dispatch({
					type: MeRepoActionTypes.GET_ME_USER_FUEL_GROUPS,
					payload: res.data.map((data: any) => ({
						...data,
						fuelGroup: {
							name: data.fuelGroups.name,
							createdOn: moment(data.fuelGroups.createdOn)
						}
					}))
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	///pending

	getAllEntertainmentExpenseRules: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/meClaim/admin/allEntertainmentExpenseRules"
			);
			console.log(res);
			if (res.status == 200) {
				dispatch({
					type: MeRepoActionTypes.GET_ME_ENTERTAINMENT_EXPENSE_RULE,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllExpenseTypes: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/meClaim/admin/allExpenseTypes"
			);
			if (res.status == 200) {
				dispatch({
					type: MeRepoActionTypes.GET_ME_EXPENSE_TYPES,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllExtraExpenseRules: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/meClaim/admin/allExtraExpenseRules"
			);
			if (res.status == 200) {
				dispatch({
					type: MeRepoActionTypes.GET_ME_EXTRA_EXPENSE_RULE,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllMeUsers: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/user/role/allUserRoles"
			);
			if (res.status == 200) {
				dispatch({
					type: MeRepoActionTypes.GET_ME_USER_ROLES,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	}

	///TO DO -> crete get dispatcher to https://lionapi.eimsky.com:8900/admin/allMealExpenseRules
});

// http://localhost:8300/role/allUserRoles

// http://localhost:8900/admin/allExpenseTypes

// http://localhost:8900/admin/allExtraExpenseRules

// http://localhost:8900/admin/allEntertainmentExpenseRules

// https://lionapi.eimsky.com:8900/admin/allMealExpenseRules

enum MeRepoActionTypes {
	GET_ME_VEHICLE_TYPES = "get_m_v_type",
	GET_ME_VEHICLE_MAKES = "get_m_v_make",
	GET_ME_VEHICLE_MODELS = "get_m_v_model",
	GET_ME_FUEL_TYPES = "get_m_f_type",
	GET_ME_FUEL_GRADES = "get_m_f_grade",
	GET_ME_FUEL_GROUPS = "get_m_f_group",
	GET_ME_USER_FUEL_GROUPS = "get_m_u_f_group",
	GET_ME_ENTERTAINMENT_EXPENSE_RULE = "get_m_e_x_r",
	GET_ME_EXTRA_EXPENSE_RULE = "get_m_e_e_r",
	GET_ME_EXPENSE_TYPES = "get_m_e_t",
	GET_ME_USER_ROLES = "get_m_users",
}

export interface MeEngineCapacity extends Identifiable {}
export interface MeVehicalType extends Identifiable {}
export interface MeVehicalMake extends Identifiable {}
export interface MeVehicalModel extends Identifiable {
	vehicalMake: MeVehicalMake;
}
export interface MeFuelType extends Identifiable {}
export interface MeFuelGrade extends Identifiable {
	unitPrice: number;
	fuelType: MeFuelType;
}
export interface MeFuelGroup extends Identifiable {
	createdOn: Moment;
}
export interface MeUserFuelGroup {
	id: string;
	fuelGroup: MeFuelGroup;
	employeeId: string;
}

export interface MeEntertainmentExpenseRule extends MeExpenseRule {}
export interface MeExtraExpenseRule extends MeExpenseRule {
	area: string;
}
export interface MeMealExpenseRule extends MeExpenseRule {}

export interface MeExpenseType extends Identifiable {}

export interface MeUser extends Identifiable {}

interface MeExpenseRule {
	id: string;
	amount: number;
	term: string;
	userRole: MeUser;
	expenseType: MeExpenseType;
}

interface Identifiable {
	name: string;
	id: string;
}

// MeClaim missing POST APIs

// createMealExpenseRule
// editMealExpenseRule
