import React, { FunctionComponent } from "react";
import {
	Empty,
	Divider,
	Button,
	Typography,
	message,
	Row,
	Input,
	Avatar,
	Col,
	Badge,
	Radio,
	Modal,
	Spin,
	Form,
	Select,
	Table
} from "antd";

import { connect } from "react-redux";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { PettyCashClaimRepo } from "../repos/PettyCashClaimRepo";
import "./style.css";
import { Tabs } from "antd";

const { TabPane } = Tabs;
const { Option } = Select;
const { Column } = Table;
const moment = require("moment");
const { Paragraph, Text } = Typography;

const layout = {
	labelCol: {
		span: 8
	},
	wrapperCol: {
		span: 16
	}
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16
	}
};

class PettyCashClaim extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			claimArea: "",
			claimAreaList: [],
			CostCenter: [],
			accountCode: [],
			Vendor: [],
			sVendor: [],
			Users: [],
			claimTypeName: "",
			costCenter: "",
			costCenterError: false,
			costCenterErrorMsg: "",
			AccountCode: "",
			AccountCodeError: false,
			AccountCodeErrorMsg: "",
			vendorCode: "",
			vendorCodeError: false,
			vendorCodeErrorMsg: "",
			vendorName: "",
			vendorNameError: false,
			vendorNameErrorMsg: "",
			description: "",
			descriptionError: false,
			descriptionErrorMsg: "",
			userId: "",
			userIdError: false,
			userIdErrorMsg: "",
			sapVenderCode: "",
			sapVenderCodeError: false,
			sapVenderCodeErrorMsg: "",
			TaxCode: "",
			loading: false
		};
	}

	async componentDidMount() {
		this.setState({
			loading: true
		});
		this.getDataList();
	}

	async getDataList() {
		await this.props.GetAllPettyCashCostCenters();
		await this.props.GetUserNameId();
		this.setState({
			CostCenter: this.props.PettyCashClaimRepo.CostCenter,
			accountCode: this.props.PettyCashClaimRepo.accountCode,
			Vendor: this.props.PettyCashClaimRepo.vendor,
			sVendor: this.props.PettyCashClaimRepo.sVendor,
			Users: this.props.PettyCashClaimRepo.users,
			loading: false
		});
	}

	onChangeCostcenterCode = async (e: any) => {
		var code = e.target.value;
		this.setState({
			costCenter: code
		});
	};

	onChangeCostcenterDescription = async (e: any) => {
		var _description = e.target.value;
		this.setState({
			description: _description
		});
	};

	onChangeAccountCode = async (e: any) => {
		var code = e.target.value;
		this.setState({
			AccountCode: code
		});
	};

	onChangeAccountCodeDescription = async (e: any) => {
		var _description = e.target.value;
		this.setState({
			description: _description
		});
	};

	onChangeTaxCode = async (e: any) => {
		var code = e.target.value;
		this.setState({
			TaxCode: code
		});
	};

	onChangeVendorCode = async (e: any) => {
		var code = e.target.value;
		this.setState({
			vendorCode: code,
			vendorCodeError: false
		});
	};

	onChangeVendorName = async (e: any) => {
		var _vendorName = e.target.value;
		this.setState({
			vendorName: _vendorName
		});
	};

	submitCostCenter = async () => {
		if (this.state.costCenter == "") {
			this.setState({
				costCenterError: true,
				costCenterErrorMsg: "Cost Center Code Cannot Be Blank"
			});
			return;
		}

		if (this.state.description == "") {
			this.setState({
				descriptionError: true,
				descriptionErrorMsg: "Cost Center Description Cannot Be Blank"
			});
			return;
		}

		this.setState({
			loading: true
		});

		var response = await this.props.SavePettyCostCenter(
			this.state.costCenter,
			this.state.description
		);
		if (response.success) {
			this.getDataList();
			this.setState({
				claimTypeName: "",
				costCenter: "",
				descriptio: ""
			});
		}
	};

	submitAccountCode = async () => {
		if (this.state.AccountCode == "") {
			this.setState({
				AccountCodeError: true,
				AccountCodeErrorMsg: "Account Code Cannot Be Blank"
			});
			return;
		}

		if (this.state.description == "") {
			this.setState({
				descriptionError: true,
				descriptionErrorMsg: "Account Code Description Cannot Be Blank"
			});
			return;
		}
		this.setState({
			loading: true
		});
		var response = await this.props.SaveAccountCode(
			this.state.AccountCode,
			this.state.description,
			this.state.TaxCode
		);
		if (response.success) {
			this.getDataList();
			this.setState({
				AccountCode: "",
				description: ""
			});
		}
	};

	changeUser = (role: any) => {
		var details = this.state.Users.find((o: { id: string }) => o.id === role);
		this.setState({
			userId: details.id
		});
	};

	onChangesapVenderCode = async (e: any) => {
		var code = e.target.value;
		this.setState({
			sapVenderCode: code
		});
	};

	submitVendor = async () => {
		if (this.state.vendorCode == "") {
			this.setState({
				vendorCodeError: true,
				vendorCodeErrorMsg: "Vendor Code Cannot Be Blank"
			});

			return;
		}

		if (this.state.vendorName == "") {
			this.setState({
				vendorNameError: true,
				vendorNameErrorMsg: "Vendor Name Cannot Be Blank"
			});
			return;
		}

		this.setState({
			loading: true
		});

		var response = await this.props.SaveVendor(
			this.state.vendorName,
			this.state.vendorCode
		);
		if (response.success) {
			this.getDataList();
			this.setState({
				vendorCode: "",
				vendorName: ""
			});
		}
	};

	submitSapVendor = async () => {
		if (this.state.userId == "") {
			this.setState({
				userIdError: true,
				userIdErrorMsg: "User Cannot Be Blank"
			});

			return;
		}

		if (this.state.sapVenderCode == "") {
			this.setState({
				sapVenderCodeError: true,
				sapVenderCodeErrorMsg: "SapVendor Code Cannot Be Blank"
			});
			return;
		}
		this.setState({
			loading: true
		});
		var response = await this.props.SaveSapVendor(
			this.state.userId,
			this.state.sapVenderCode
		);
		if (response.success) {
			this.getDataList();
			this.setState({
				userId: "",
				sapVenderCode: ""
			});
		}
	};

	getCostCenter() {
		return (
			<Row>
				<Col span={14}>
					<Table
						pagination={{ pageSize: 4 }}
						style={{ marginTop: 26, padding: 5 }}
						dataSource={this.state.CostCenter.reverse()}>
						<Column
							title='*'
							key='index'
							render={(value, item, index) => index + 1}
						/>
						{/* <Column title="Role Id" dataIndex="roleId" key="roleId" render={(value, item , index) => value}/> */}
						<Column title='Cost Center ID' dataIndex='costCenter' key='costCenter' />
						<Column
							title='Description'
							dataIndex='description'
							key='description'
							render={(value, item, index) => value}
						/>
					</Table>
				</Col>
				<Divider
					type='vertical'
					style={{ height: 350, width: 10, marginLeft: 14 }}></Divider>
				<Col span={9}>
					<h3 style={{ margin: 10 }}>Create Cost Center</h3>
					<Form {...layout} name='control-hooks'>
						<Form.Item label='* Code'>
							<Input
								onChange={this.onChangeCostcenterCode}
								value={this.state.costCenter}
							/>
							{this.state.costCenterError && (
								<p style={{ color: "#d9363e", fontSize: 9 }}>
									{this.state.costCenterErrorMsg}
								</p>
							)}
						</Form.Item>
						<Form.Item label='* Description'>
							<Input
								onChange={this.onChangeCostcenterDescription}
								value={this.state.description}
							/>
							{this.state.descriptionError && (
								<p style={{ color: "#d9363e", fontSize: 9 }}>
									{this.state.descriptionErrorMsg}
								</p>
							)}
						</Form.Item>

						<Form.Item {...tailLayout}>
							<Button type='primary' onClick={this.submitCostCenter}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		);
	}

	getAccountCode() {
		return (
			<Row>
				<Col span={14}>
					<Table
						pagination={{ pageSize: 4 }}
						style={{ marginTop: 26, padding: 5 }}
						dataSource={this.state.accountCode.reverse()}>
						<Column
							title='*'
							key='index'
							render={(value, item, index) => index + 1}
						/>
						{/* <Column title="Role Id" dataIndex="roleId" key="roleId" render={(value, item , index) => value}/> */}
						<Column title='Account Code' dataIndex='accountCode' key='accountCode' />
						<Column
							title='Description'
							dataIndex='description'
							key='description'
							render={(value, item, index) => value}
						/>
						<Column title='Tax Code' dataIndex='taxCode' key='taxCode' />
					</Table>
				</Col>
				<Divider
					type='vertical'
					style={{ height: 350, width: 10, marginLeft: 14 }}></Divider>
				<Col span={9}>
					<h3 style={{ margin: 10 }}>Create Account Code</h3>
					<Form {...layout} name='control-hooks'>
						<Form.Item label='* Code'>
							<Input
								onChange={this.onChangeAccountCode}
								value={this.state.AccountCode}
							/>
							{this.state.costCenterError && (
								<p style={{ color: "#d9363e", fontSize: 9 }}>
									{this.state.costCenterErrorMsg}
								</p>
							)}
						</Form.Item>
						<Form.Item label='* Description'>
							<Input
								onChange={this.onChangeAccountCodeDescription}
								value={this.state.description}
							/>
							{this.state.descriptionError && (
								<p style={{ color: "#d9363e", fontSize: 9 }}>
									{this.state.descriptionErrorMsg}
								</p>
							)}
						</Form.Item>

						<Form.Item label='Tax Code'>
							<Input onChange={this.onChangeTaxCode} value={this.state.TaxCode} />
							{this.state.TaxCode && (
								<p style={{ color: "#d9363e", fontSize: 9 }}>
									{this.state.descriptionErrorMsg}
								</p>
							)}
						</Form.Item>

						<Form.Item {...tailLayout}>
							<Button type='primary' onClick={this.submitAccountCode}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		);
	}

	getVendor() {
		return (
			<Row>
				<Col span={14}>
					<Table
						pagination={{ pageSize: 4 }}
						style={{ marginTop: 26, padding: 5 }}
						dataSource={this.state.Vendor.reverse()}>
						<Column title='' key='index' render={(value, item, index) => index + 1} />
						{/* <Column title="Role Id" dataIndex="roleId" key="roleId" render={(value, item , index) => value}/> */}
						<Column title='Vendor Code' dataIndex='vendorCode' key='vendorCode' />
						<Column
							title='Vendor Name'
							dataIndex='vendorName'
							key='vendorName'
							render={(value, item, index) => value}
						/>
					</Table>
				</Col>
				<Divider
					type='vertical'
					style={{ height: 350, width: 10, marginLeft: 14 }}></Divider>
				<Col span={9}>
					<h3 style={{ margin: 10 }}>Create Vendor</h3>
					<Form {...layout} name='control-hooks'>
						<Form.Item label='* Code'>
							<Input
								onChange={this.onChangeVendorCode}
								value={this.state.vendorCode}
							/>
							{this.state.vendorCodeError && (
								<p style={{ color: "#d9363e", fontSize: 9 }}>
									{this.state.vendorCodeErrorMsg}
								</p>
							)}
						</Form.Item>
						<Form.Item label='* Vendor Name'>
							<Input
								onChange={this.onChangeVendorName}
								value={this.state.vendorName}
							/>
							{this.state.vendorNameError && (
								<p style={{ color: "#d9363e", fontSize: 9 }}>
									{this.state.vendorNameErrorMsg}
								</p>
							)}
						</Form.Item>

						<Form.Item {...tailLayout}>
							<Button type='primary' onClick={this.submitVendor}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		);
	}

	getSapVendor() {
		return (
			<Row>
				<Col span={14}>
					<Table
						pagination={{ pageSize: 4 }}
						style={{ marginTop: 26, padding: 5 }}
						dataSource={this.state.sVendor.reverse()}>
						<Column title='' key='index' render={(value, item, index) => index + 1} />
						{/* <Column title="Role Id" dataIndex="roleId" key="roleId" render={(value, item , index) => value}/> */}
						<Column
							title='Vendor Code'
							dataIndex='sap_Vendor_Code'
							key='sap_Vendor_Code'
						/>
						<Column
							title='User ID'
							dataIndex='userID'
							key='userID'
							render={(value, item, index) => value}
						/>
					</Table>
				</Col>
				<Divider
					type='vertical'
					style={{ height: 350, width: 10, marginLeft: 14 }}></Divider>
				<Col span={9}>
					<h3 style={{ margin: 10 }}>Create Vendor</h3>
					<Form {...layout} name='control-hooks'>
						<Form.Item label='* User'>
							<Select
								onChange={this.changeUser}
								showSearch
								optionFilterProp='children'>
								{this.state.Users.map((key: any) => (
									<Select.Option value={key.id}>{key.userName}</Select.Option>
								))}
							</Select>
							{this.state.userIdError && (
								<p style={{ color: "#d9363e", fontSize: 9 }}>
									{this.state.userIdErrorMsg}
								</p>
							)}
						</Form.Item>
						<Form.Item label='* Sap Vender Code'>
							<Input
								onChange={this.onChangesapVenderCode}
								value={this.state.sapVenderCode}
							/>
							{this.state.sapVenderCodeError && (
								<p style={{ color: "#d9363e", fontSize: 9 }}>
									{this.state.sapVenderCodeErrorMsg}
								</p>
							)}
						</Form.Item>

						<Form.Item {...tailLayout}>
							<Button type='primary' onClick={this.submitSapVendor}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		);
	}

	getActiveQuataView() {
		return <Row></Row>;
	}

	render() {
		return (
			<div>
				<Spin
					size='small'
					tip='Data Loading'
					spinning={this.state.loading}
					delay={500}>
					<Tabs defaultActiveKey='1' type='card' size={"small"}>
						<TabPane tab='Cost Center' key='1'>
							{this.getCostCenter()}
						</TabPane>
						<TabPane tab='Account Code' key='2'>
							{this.getAccountCode()}
						</TabPane>
						<TabPane tab='Vendor' key='3'>
							{this.getVendor()}
						</TabPane>
						<TabPane tab='Sap Vendor' key='4'>
							{this.getSapVendor()}
						</TabPane>
					</Tabs>
					{/* <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                </Spin> */}
				</Spin>
			</div>
		);
	}
}

const withMedicalClaimRepo = connect(
	(state: any) => state,
	PettyCashClaimRepo
)(PettyCashClaim);

export default withMedicalClaimRepo;
