import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { FuelClaimRepoActions } from "../repos/FuelClaimRepo";
import "./style.css";

import { Tabs } from "antd";
import { FuelVehicleTypeTab } from "./tabs/fuel/FuelVehicleTypeTab";
import { FuelVehicleModelTab } from "./tabs/fuel/FuelVehicleModelTab";
import { FuelVehicleMakeTab } from "./tabs/fuel/FuelVehicleMakeTab";
import { FuelFuelTypeTab } from "./tabs/fuel/FuelFuelTypeTab";
import { FuelFuelGradeTab } from "./tabs/fuel/FuelFuelGradeTab";
import { FuelEngineCapacityTab } from "./tabs/fuel/FuelEngineCapacityTab";
import { FuelFuelGradeRangeTab } from "./tabs/fuel/FuelFuelGradeRangeTab";

class FuelClaimTabKeys {
	static F_VEHICLE_TYPE = "1";
	static F_VEHICLE_MODEL = "2";
	static F_VEHICLE_MAKE = "3";
	static F_FUEL_TYPE = "4";
	static F_FUEL_GRADE = "5";
	static F_ENGINE_CAPACITY = "6";
	static F_FUEL_GRADE_RANGE = "7";
}

class FuelClaim extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.fetchTabData = this.fetchTabData.bind(this);
	}

	componentDidMount() {
		this.fetchTabData(FuelClaimTabKeys.F_VEHICLE_TYPE);
	}

	async fetchTabData(key: FuelClaimTabKeys) {
		try {
			switch (key) {
				case FuelClaimTabKeys.F_VEHICLE_TYPE:
					await this.props.getAllVehicleTypes();
					break;
				case FuelClaimTabKeys.F_VEHICLE_MODEL:
					await this.props.getAllVehicleMakes();
					await this.props.getAllVehicleModels();
					break;
				case FuelClaimTabKeys.F_VEHICLE_MAKE:
					await this.props.getAllVehicleMakes();
					break;
				case FuelClaimTabKeys.F_FUEL_TYPE:
					await this.props.getAllFuelTypes();
					break;
				case FuelClaimTabKeys.F_FUEL_GRADE:
					await this.props.getAllFuelTypes();
					await this.props.getAllFuelGrades();
					break;
				case FuelClaimTabKeys.F_ENGINE_CAPACITY:
					await this.props.getAllEngineCapacities();
					break;
				case FuelClaimTabKeys.F_FUEL_GRADE_RANGE:
					await this.props.getAllFuelGrades();
					await this.props.getAllFuelGradeRanges();
					break;
			}
		} catch (err) {}
	}

	render() {
		return (
			<div>
				<Tabs
					type='card'
					size='small'
					defaultActiveKey={FuelClaimTabKeys.F_VEHICLE_TYPE}
					onChange={key => this.fetchTabData(key)}>
					<Tabs.TabPane tab='Vehicle Type' key={FuelClaimTabKeys.F_VEHICLE_TYPE}>
						<FuelVehicleTypeTab
							sourceData={this.props.FuelClaimRepo.vehicleTypes.reverse()}
							onDone={() => this.fetchTabData(FuelClaimTabKeys.F_VEHICLE_TYPE)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab='Vehicle Model' key={FuelClaimTabKeys.F_VEHICLE_MODEL}>
						<FuelVehicleModelTab
							sourceData={this.props.FuelClaimRepo.vehicleModels.reverse()}
							onDone={() => this.fetchTabData(FuelClaimTabKeys.F_VEHICLE_MODEL)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab='Vehicle Make' key={FuelClaimTabKeys.F_VEHICLE_MAKE}>
						<FuelVehicleMakeTab
							sourceData={this.props.FuelClaimRepo.vehicleMakes.reverse()}
							onDone={() => this.fetchTabData(FuelClaimTabKeys.F_VEHICLE_MAKE)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab='Fuel Type' key={FuelClaimTabKeys.F_FUEL_TYPE}>
						<FuelFuelTypeTab
							sourceData={this.props.FuelClaimRepo.fuelTypes.reverse()}
							onDone={() => this.fetchTabData(FuelClaimTabKeys.F_FUEL_TYPE)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab='Fuel Grade' key={FuelClaimTabKeys.F_FUEL_GRADE}>
						<FuelFuelGradeTab
							sourceData={this.props.FuelClaimRepo.fuelGrades.reverse()}
							onDone={() => this.fetchTabData(FuelClaimTabKeys.F_FUEL_GRADE)}
						/>
					</Tabs.TabPane>
					{/* <Tabs.TabPane
						tab='Engine Capacity'
						key={FuelClaimTabKeys.F_ENGINE_CAPACITY}>
						<FuelEngineCapacityTab
							sourceData={this.props.FuelClaimRepo.engineCapacities.reverse()}
							onDone={() => this.fetchTabData(FuelClaimTabKeys.F_ENGINE_CAPACITY)}
						/>
					</Tabs.TabPane> */}
					<Tabs.TabPane
						tab='Fuel Grade Range'
						key={FuelClaimTabKeys.F_FUEL_GRADE_RANGE}>
						<FuelFuelGradeRangeTab
							sourceData={this.props.FuelClaimRepo.fuelGradeRanges.reverse()}
							onDone={() => this.fetchTabData(FuelClaimTabKeys.F_FUEL_GRADE_RANGE)}
						/>
					</Tabs.TabPane>
				</Tabs>
			</div>
		);
	}
}

const withFuelClaimRepo = connect(
	(state: any) => state,
	FuelClaimRepoActions
)(FuelClaim);

export default withFuelClaimRepo;
