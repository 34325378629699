import React from "react";
import { MasterdataEditTab } from "../../../components/MasterDataTab";
import { MasterDataFormMode, MasterDataForm } from "../../../components/Form";
import { Button, Input, Form, Select, Table, Alert, message } from "antd";
import { lionUserManager } from "../../../repos/UserRepo";
import { texts } from "../../../texts.config";
import { EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import {
	MeVehicalMake,
	MeVehicalModel,
	MeClaimRepoActions
} from "../../../repos/MeClaimRepo";

export class MeVehicleModelTab extends MasterdataEditTab<MeVehicalModel> {
	_onModeChange(mode: MasterDataFormMode) {
		super.clearItem();
	}

	async _validateDuplicates(rule: any, value: any) {
		if (this.props.sourceData) {
			if (this.props.sourceData.find(data => data.name === value))
				throw new Error("Vehicle model already exists");
		} else throw new Error("Vehicle models not found");
	}

	render() {
		return (
			<>
				{this.state.item ? (
					<MeFuelGradeFormWithRepo
						initialValue={this.state.item}
						validators={{}}
						onModeChange={this._onModeChange.bind(this)}
						onDone={async (data: MeVehicalModel) => {
							let _hide = message.loading("Action in progress..", 0);
							try {
								const res = await lionUserManager.authenticationManager.call(
									"put",
									`/meClaim/admin/editVehicalModel/${data.id}`,
									{ name: data.name, status: 1, vehicalMake: data.vehicalMake }
								);

								if (res.status == 201) {
									message.success("Done");
									super.onDone(data);
									super.clearItem();
								}
							} catch (err) {
								message.error(texts.DEFAULT_ERROR_MESSAGE);
							} finally {
								_hide();
							}
						}}
						key='me-vehicle-model-edit'
						mode={MasterDataFormMode.EDIT}
					/>
				) : (
					<MeFuelGradeFormWithRepo
						initialValue={{ name: "", id: "", vehicalMake: { name: "", id: "" } }}
						validators={{
							check_duplicate: this._validateDuplicates.bind(this)
						}}
						onDone={async (data: MeVehicalModel) => {
							let _hide = message.loading("Action in progress..", 0);
							try {
								const res = await lionUserManager.authenticationManager.post(
									"/meClaim/admin/createVehicalModel",
									{ name: data.name, status: 1, vehicalMake: data.vehicalMake }
								);

								if (res.status == 201) {
									message.success("Done");
									super.onDone(data);
								}
							} catch (err) {
								message.error(texts.DEFAULT_ERROR_MESSAGE);
							} finally {
								_hide();
							}
						}}
						key='me-vehicle-model-create'
						mode={MasterDataFormMode.CREATE}
					/>
				)}
				<Table dataSource={this.props.sourceData}>
					<Table.Column
						title='No'
						key='index'
						render={(_, item: MeVehicalModel, index) => index + 1}
					/>
					<Table.Column title='Model Name ' dataIndex='name' />
					<Table.Column
						title='Vehicle Make'
						render={(_, item: MeVehicalModel, __) => item.vehicalMake ? item.vehicalMake.name : "N/A"}
					/>
					<Table.Column
						title='Action'
						key='action'
						render={(_, item: MeVehicalModel, __) => (
							<span>
								{/* <DeleteOutlined style={{ color: "#de2f38" }} /> */}
								<EditOutlined
									style={{ color: "#de2f38", marginLeft: 10 }}
									onClick={() => this.setItem(item)}
								/>
							</span>
						)}
					/>
				</Table>
			</>
		);
	}
}

class MeVehicalModelForm extends MasterDataForm<MeVehicalModel, any> {
	async _onDone() {
		if (this.formRef.current && (await this.validate())) {
			const { MeClaimRepo } = this.props as any;
			const vMakeId = this.formRef.current.getFieldValue("vmake");

			super.onDone({
				id: this.props.initialValue.id,
				name: this.formRef.current.getFieldValue("mname"),
				vehicalMake: MeClaimRepo.vehicleMakes.find(
					(value: MeVehicalMake, i: number, s: any) => value.id == vMakeId
				)
			});
		}
	}

	render() {
		const { MeClaimRepo } = this.props as any;

		return (
			<>
				{this.props.mode == MasterDataFormMode.EDIT && (
					<Alert
						message='Currently in Edit state.'
						type='info'
						closeText='Exit Now'
						onClose={() => super.changeMode(MasterDataFormMode.CREATE)}
					/>
				)}
				<Form
					// {...layout}
					ref={this.formRef}
					initialValues={{
						type: this.props.initialValue.name
					}}
					onFinish={this._onDone.bind(this)}>
					<Form.Item
						name='mname'
						label='Modle Name'
						rules={[
							{
								validator: async (rule, value) => {
									if (this.props.validators["check_duplicate"])
										await this.props.validators["check_duplicate"](rule, value);
								}
							},
							{
								required: true,
								message: "Model name is require"
							}
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name='vmake'
						label='Vehicle Make'
						rules={[
							{
								required: true,
								message: "Vehicle make is require"
							}
						]}>
						<Select allowClear disabled={MeClaimRepo.vehicleMakes.length < 1}>
							{MeClaimRepo.vehicleMakes.map((type: MeVehicalMake) => (
								<Select.Option value={type.id}>{type.name}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</>
		);
	}
}

export const MeFuelGradeFormWithRepo = connect(
	(state: any) => state,
	MeClaimRepoActions
)(MeVehicalModelForm);
