import { default as Axios, AxiosAdapter, AxiosError, AxiosResponse} from 'axios';
import { headers as defaultHeaders, baseUrl } from '../api.config'

const instance = Axios.create({
    timeout: 10000,
    headers : {"Content-type":"application/json"}
});

export const get = (url: string, _headers: any | undefined) => instance.get(baseUrl+url, { headers: {...defaultHeaders, ..._headers}});
export const post = (url: string, body: any, _headers: any | undefined) => instance.post(baseUrl+url, body,{ headers: {...defaultHeaders, ..._headers}});
export const put = (url: string, body: any, _headers: any | undefined) => instance.put(baseUrl+url, body,{ headers: {...defaultHeaders, ..._headers}});