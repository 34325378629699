import React from "react";
import { Button, Input, Form, Select, Table, Alert, message, Row, Col } from "antd";
import { MasterdataEditTab } from "../../components/MasterDataTab";
import { MasterDataForm, MasterDataFormMode } from "../../components/Form";
import { lionUserManager } from "../../repos/UserRepo";
import { texts } from "../../texts.config";

interface DepartmentType{
	eclaimHiecode: string, 
	eclaimHiecodeName: string, 
	syncStructureLevelCode: string, 
	syncStructureLevelName: string
}


export class DepartmentTypeTab extends MasterdataEditTab<DepartmentType> {
	_onModeChange(mode: MasterDataFormMode) {
		super.clearItem();
	}

	render() {
		return (
			<>
				{
					<DepartmentTypeForm
						validators={{}}
						initialValue={{
                                eclaimHiecode: "", 
                                eclaimHiecodeName: "", 
                                syncStructureLevelCode: "", 
                                syncStructureLevelName: ""
                            }
                        }
						onDone={
                            async (data: DepartmentType) => {
                                let _hide = message.loading("Action in progress..", 0);
                                try {
                                    const res = await lionUserManager.authenticationManager.post(
                                        "/user/admin/createUserDepartment", 
                                        {   
                                            "eclaimHiecode" : data.eclaimHiecode, 
                                            "eclaimHiecodeName" : data.eclaimHiecodeName, 
                                            "syncStructureLevelCode" : data.syncStructureLevelCode,
                                            "syncStructureLevelName" : data.syncStructureLevelName
                                        }
                                    );

                                    if (res.status == 201) {
                                        message.success("Done");
                                        super.onDone(data);
                                    }
                                } catch (err) {
                                    message.error(texts.DEFAULT_ERROR_MESSAGE);
                                } finally {
                                    _hide();
                                }
						}}
						key='me-vehicle-type-create'
						mode={MasterDataFormMode.CREATE}
					/>
				}
				<Table dataSource={this.props.sourceData}>
					<Table.Column
						title='eClaim Hiecode'
						key='eclaimHiecode'
						render={(value, item, index) => value.eclaimHiecode}
					/>
					<Table.Column
						title='eClaim Hiecode Name'
						key='eclaimHiecodeName'
						render={(value, item, index) => value.eclaimHiecodeName}
					/>
                    <Table.Column
						title='Sync Structure Level Code'
						key='syncStructureLevelCode'
						render={(value, item, index) => value.syncStructureLevelCode}
					/>
                    <Table.Column
						title='Sync Structure Level Name'
						key='syncStructureLevelName'
						render={(value, item, index) => value.syncStructureLevelName}
					/>
				</Table>
			</>
		);
	}
}

class DepartmentTypeForm extends MasterDataForm<DepartmentType, any> {
	
	async _onDone() {
		if (this.formRef.current && (await this.validate()))
        super.onDone({
            eclaimHiecode: this.formRef.current.getFieldValue("eclaimHiecode"),
			eclaimHiecodeName:  this.formRef.current.getFieldValue("eclaimHiecodeName"), 
			syncStructureLevelCode: this.formRef.current.getFieldValue("syncStructureLevelCode"),
			syncStructureLevelName: this.formRef.current.getFieldValue("syncStructureLevelName")
        });
	}


	render() {
		return (
			<>
				<Form
					// {...layout}
					ref={this.formRef}
					initialValues={{
						eclaimHiecode: this.props.initialValue.eclaimHiecode,
						eclaimHiecodeName:  this.props.initialValue.eclaimHiecodeName, 
						syncStructureLevelCode: this.props.initialValue.syncStructureLevelCode, 
						syncStructureLevelName: this.props.initialValue.syncStructureLevelName
					}}
					onFinish={this._onDone.bind(this)}>
					<Row >
						<Col style={{paddingRight: 100}}>
							<Form.Item
								name='eclaimHiecode'
								label='eClaim Hiecode'
								rules={[
									{
										validator: async (rule, value) => {
											if (this.props.validators["check_duplicate"])
												await this.props.validators["check_duplicate"](rule, value);
										}
									},
									{
										required: true,
										message: "Type is required"
									}
								]}>
								<Input />
							</Form.Item>
							<Form.Item
								name='syncStructureLevelCode'
								label='Sync Structure Level Code'
								rules={[
									{
										validator: async (rule, value) => {
											if (this.props.validators["check_duplicate"])
												await this.props.validators["check_duplicate"](rule, value);
										}
									},
									{
										required: true,
										message: "Type is required"
									}
								]}>
								<Input />
							</Form.Item>
						</Col>
						
						<Col>
							<Form.Item
								name='eclaimHiecodeName'
								label='eClaim Hiecode Name'
								rules={[
									{
										validator: async (rule, value) => {
											if (this.props.validators["check_duplicate"])
												await this.props.validators["check_duplicate"](rule, value);
										}
									},
									{
										required: true,
										message: "Type is required"
									}
								]}>
								<Input />
							</Form.Item>
							<Form.Item
								name='syncStructureLevelName'
								label='Sync Structure Level Name'
								rules={[
									{
										validator: async (rule, value) => {
											if (this.props.validators["check_duplicate"])
												await this.props.validators["check_duplicate"](rule, value);
										}
									},
									{
										required: true,
										message: "Type is required"
									}
								]}>
								<Input />
							</Form.Item>
						</Col>		
					</Row>
                    
					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</>
		);
	}
}
