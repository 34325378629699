import React from "react";
import { MasterdataEditTab } from "../../../components/MasterDataTab";
import { MasterDataFormMode, MasterDataForm } from "../../../components/Form";
import {
	Button,
	Input,
	Form,
	Select,
	Table,
	Alert,
	message,
	InputNumber,
	Row,
	Col,
	Descriptions
} from "antd";
import { lionUserManager } from "../../../repos/UserRepo";
import { texts } from "../../../texts.config";
import { EditOutlined } from "@ant-design/icons";
import {
	MeUserFuelGroup,
	MeClaimRepoActions,
	MeFuelGroup
} from "../../../repos/MeClaimRepo";
import moment from "moment";
import { connect } from "react-redux";

export class MeUserFuelGroupTab extends MasterdataEditTab<MeUserFuelGroup> {
	_onModeChange(mode: MasterDataFormMode) {
		super.clearItem();
	}

	render() {
		return (
			<>
				{this.state.item ? (
					<MeUserFuelGroupFormWithRepo
						initialValue={this.state.item}
						onModeChange={this._onModeChange.bind(this)}
						onDone={async (data: MeUserFuelGroup) => {
							let _hide = message.loading("Action in progress..", 0);
							try {
								const res = await lionUserManager.authenticationManager.post(
									`/meClaim/admin/editUserFuelGroup`,
									{ ...data, status: 1, fuelGroups: data.fuelGroup }
								);

								if (res.status == 200) {
									message.success("Done");
									super.onDone(data);
								}
							} catch (err) {
								message.error(texts.DEFAULT_ERROR_MESSAGE);
							} finally {
								_hide();
							}
						}}
						key='me-user-fuel-group-edit'
						mode={MasterDataFormMode.EDIT}
					/>
				) : (
					<MeUserFuelGroupFormWithRepo
						initialValue={{
							id: "",
							employeeId: "",
							fuelGroup: { id: "", name: "", createdOn: moment() }
						}}
						onDone={async (data: MeUserFuelGroup) => {
							let _hide = message.loading("Action in progress..", 0);
							try {
								const res = await lionUserManager.authenticationManager.post(
									"/meClaim/admin/createUserFuelGroup",
									{ ...data, status: 1, fuelGroups: data.fuelGroup }
								);

								if (res.status == 201) {
									message.success("Done");
									super.onDone(data);
								}
							} catch (err) {
								message.error(texts.DEFAULT_ERROR_MESSAGE);
							} finally {
								_hide();
							}
						}}
						key='me-user-fuel-group-create'
						mode={MasterDataFormMode.CREATE}
					/>
				)}
				<Table dataSource={this.props.sourceData}>
					<Table.Column
						title='No'
						key='index'
						render={(value, item, index) => index + 1}
					/>
					<Table.Column title='Employee ID' dataIndex='employeeId' />
					<Table.Column
						title='Group'
						key='fuelGroups'
						render={(value, item: MeUserFuelGroup, index) => (
							
							<Col>
								<Row>Name : {item.fuelGroup.name}</Row>
								<Row>Date : {item.fuelGroup.createdOn.format("YYYY-MM-DD")}</Row>
							</Col>
						)}
					/>
					<Table.Column
						title='Action'
						key='action'
						render={(value, item: MeUserFuelGroup, index) => (
							<span>
								{/* <DeleteOutlined style={{ color: "#de2f38" }} /> */}
								<EditOutlined
									style={{ color: "#de2f38", marginLeft: 10 }}
									onClick={() => this.setItem(item)}
								/>
							</span>
						)}
					/>
				</Table>
			</>
		);
	}
}

class MeUserFuelGroupForm extends MasterDataForm<MeUserFuelGroup, any> {
	async _onDone() {
		if (this.formRef.current && (await this.validate())) {
			const { MeClaimRepo } = this.props as any;
			const fGroupId = this.formRef.current.getFieldValue("fgroup");
			super.onDone({
				id: this.props.initialValue.id,
				fuelGroup: MeClaimRepo.fuelGroups.find(
					(value: MeFuelGroup, i: number, s: any) => value.id == fGroupId
				),
				employeeId: this.formRef.current.getFieldValue("user")
			});
		}
	}

	render() {
		const { MeClaimRepo } = this.props as any;

		return (
			<>
				{this.props.mode == MasterDataFormMode.EDIT && (
					<Alert
						message='Currently in Edit state.'
						type='info'
						closeText='Exit Now'
						onClose={() => super.changeMode(MasterDataFormMode.CREATE)}
					/>
				)}
				<Form
					// {...layout}
					ref={this.formRef}
					initialValues={{
						fgroup: this.props.initialValue.fuelGroup.id,
						user: this.props.initialValue.employeeId
					}}
					onFinish={this._onDone.bind(this)}>
					<Form.Item
						name='user'
						label='Employee ID'
						rules={[
							{
								required: true,
								message: "Employee id is required"
							}
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name='fgroup'
						label='Fuel Group'
						rules={[
							{
								required: true,
								message: "Fuel group is required"
							}
						]}>
						<Select allowClear disabled={MeClaimRepo.fuelGroups.length < 1}>
							{MeClaimRepo.fuelGroups.map((group: MeFuelGroup) => (
								<Select.Option value={group.id}>{group.name}</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</>
		);
	}
}

const MeUserFuelGroupFormWithRepo = connect(
	(state: any) => state,
	MeClaimRepoActions
)(MeUserFuelGroupForm);
