import React, { FunctionComponent } from 'react';
import { Empty, Divider, Button, Typography,message, Row,Input, Avatar, Col, Badge ,Radio, Modal ,Spin, Form ,   Select,  Table, PageHeader } from 'antd';
 
import { connect } from 'react-redux';
import { EditOutlined, FundViewOutlined } from '@ant-design/icons';
import { Tabs  } from 'antd';
import { DepartmentManagementRepo } from '../../repos/DepartmentManagementRepo';
import { MeVehicleTypeTab } from '../tabs/me/MeVehicleTypeTab';
import { DepartmentTypeTab } from './DepartmentTab';

const { TabPane } = Tabs;
const { Option } = Select;
const { Column } = Table;
const moment = require('moment');
 
  const layout = {
    labelCol: {
      span:5,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 5,
      span: 16,
    },
  };
  

  class DepartmentTabKeys {
    static DEPARTMENTS = "1";
  }
  
 
class DepartmentManagement extends React.Component<any, any> { 
    constructor(props: any) {
        super(props); 
        this.state = {
          departments : [],
          loading : false,
        }
    }

    async componentDidMount() {
      this.setState({
        loading : true
      });
      this.getData();
    }
 
    async getData() {
      var _departmentsList = await this.props.GetAllDepartments(); 
      this.setState({
        loading : false,
        departments : _departmentsList.reverse()
      });
    }

    async fetchTabData(key: string) {
      try {
        switch (key) {
          case DepartmentTabKeys.DEPARTMENTS: {
            await this.props.getAllVehicleTypes();
            break;
          }
        }
      } catch (err) {}
    }
    
    getMasterDataView(){
        return <Row>
        <Col span={24}>

          <Tabs
            defaultActiveKey='1'
            type='card'
            size={"small"}
            onChange={this.fetchTabData.bind(this)}>
            <Tabs.TabPane tab='Departments' key={DepartmentTabKeys.DEPARTMENTS}>
              <DepartmentTypeTab
                sourceData={this.state.departments}
                onDone={() => {
                  this.getData();
                }}
              />
            </Tabs.TabPane>
          </Tabs>
           
        </Col>
          
    </Row>
   
  }


  render() {

        return (
            <div>
              <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
			   
                {/* <Tabs defaultActiveKey="1" type="card" size={'small'}>
                    <TabPane tab="Loan Amount" key="1">
					<h5>View History</h5> */}
                      {this.getMasterDataView()}
                    {/* </TabPane>
                    
                     
                </Tabs> */}
               </Spin>
               {/* <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                </Spin> */}
            </div>
        )
    }
}

const withDepartmentManagementRepo = connect(
	(state: any) => state,
	DepartmentManagementRepo
)(DepartmentManagement);

export default withDepartmentManagementRepo;
