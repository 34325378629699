import { texts } from "../texts.config";
import { lionUserManager } from "./UserRepo";
import { MeVehicalMake } from "./MeClaimRepo";
import moment, { Moment } from "moment";

interface FuelRepoState {
	vehicleTypes: FuelVehicleType[];
	vehicleModels: FuelVehicleModel[];
	vehicleMakes: FuelVehicleMake[];
	fuelTypes: FuelFuelType[];
	fuelGrades: FuelFuelGrade[];
	engineCapacities: FuelEngineCapacity[];
	fuelGradeRanges: FuelFuelGradeRange[];
}

const MeRepoDefaultState: FuelRepoState = {
	vehicleTypes: [],
	vehicleModels: [],
	vehicleMakes: [],
	fuelTypes: [],
	fuelGrades: [],
	engineCapacities: [],
	fuelGradeRanges: []
};

export const Repo = (
	state: FuelRepoState = MeRepoDefaultState,
	action: { type: FuelRepoActionTypes; payload: any }
): FuelRepoState => {
	switch (action.type) {
		case FuelRepoActionTypes.GET_FUEL_VEHICLE_TYPES: {
			return { ...state, vehicleTypes: action.payload };
		}
		case FuelRepoActionTypes.GET_FUEL_VEHICLE_MODELS: {
			return { ...state, vehicleModels: action.payload };
		}
		case FuelRepoActionTypes.GET_FUEL_VEHICLE_MAKES: {
			return { ...state, vehicleMakes: action.payload };
		}
		case FuelRepoActionTypes.GET_FUEL_FUEL_TYPES: {
			return { ...state, fuelTypes: action.payload };
		}
		case FuelRepoActionTypes.GET_FUEL_FUEL_GRADES: {
			return { ...state, fuelGrades: action.payload };
		}
		case FuelRepoActionTypes.GET_FUEL_ENGINE_CAPACITIES: {
			return { ...state, engineCapacities: action.payload };
		}
		case FuelRepoActionTypes.GET_FUEL_FUEL_GRADE_RANGES: {
			return { ...state, fuelGradeRanges: action.payload };
		}
		default:
			return state;
	}
};

export interface IFuelClaimRepoActions {
	getAllVehicleTypes: () => Promise<any>;
	getAllVehicleModels: () => Promise<any>;
	getAllVehicleMakes: () => Promise<any>;
	getAllFuelTypes: () => Promise<any>;
	getAllFuelGrades: () => Promise<any>;
	getAllEngineCapacities: () => Promise<any>;
	getAllFuelGradeRanges: () => Promise<any>;
}

export const FuelClaimRepoActions = (dispatch: any): IFuelClaimRepoActions => ({
	getAllVehicleTypes: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/fuelClaim/vehicle/AllListVehicleType"
			);
			if (res.status == 200) {
				dispatch({
					type: FuelRepoActionTypes.GET_FUEL_VEHICLE_TYPES,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllVehicleModels: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/fuelClaim/vehicle/AllListVehicalModel"
			);
			if (res.status == 200) {
				dispatch({
					type: FuelRepoActionTypes.GET_FUEL_VEHICLE_MODELS,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllVehicleMakes: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/fuelClaim/vehicle/AllListVehicalMake"
			);
			if (res.status == 200) {
				dispatch({
					type: FuelRepoActionTypes.GET_FUEL_VEHICLE_MAKES,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllFuelTypes: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/fuelClaim/vehicle/AllListFuelType"
			);
			if (res.status == 200) {
				dispatch({
					type: FuelRepoActionTypes.GET_FUEL_FUEL_TYPES,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllFuelGrades: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/fuelClaim/vehicle/AllListFuelGrade"
			);
			if (res.status == 200) {
				dispatch({
					type: FuelRepoActionTypes.GET_FUEL_FUEL_GRADES,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllEngineCapacities: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/fuelClaim/vehicle/AllListEngineCapacity"
			);
			if (res.status == 200) {
				dispatch({
					type: FuelRepoActionTypes.GET_FUEL_ENGINE_CAPACITIES,
					payload: res.data
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getAllFuelGradeRanges: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/fuelClaim/vehicle/AllListFuelGradeRange"
			);
			if (res.status == 200) {
				dispatch({
					type: FuelRepoActionTypes.GET_FUEL_FUEL_GRADE_RANGES,
					payload: res.data.map((data: any) => ({
						...data,
						startDate: moment(data.startDate),
						endDate: moment(data.endDate)
					}))
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	}
});

enum FuelRepoActionTypes {
	GET_FUEL_VEHICLE_TYPES = "get_f_v_types",
	GET_FUEL_VEHICLE_MODELS = "get_f_v_models",
	GET_FUEL_VEHICLE_MAKES = "get_f_v_makes",
	GET_FUEL_FUEL_TYPES = "get_f_f_type",
	GET_FUEL_FUEL_GRADES = "get_f_f_grade",
	GET_FUEL_ENGINE_CAPACITIES = "get_f_e_cap",
	GET_FUEL_FUEL_GRADE_RANGES = "get_f_f_g_range"
}

export interface FuelVehicleType extends Identifiable {}
export interface FuelVehicleModel extends Identifiable {
	make: MeVehicalMake;
}
export interface FuelVehicleMake extends Identifiable {}
export interface FuelFuelType extends Identifiable {
	unitPrice: number;
}
export interface FuelFuelGrade extends Identifiable {
	unitPrice: number;
	fuelType: FuelFuelType;
}
export interface FuelEngineCapacity extends Identifiable {
	capacity: number;
}

export interface FuelFuelGradeRange extends Identifiable {
	startDate: Moment;
	endDate: Moment;
	max: number;
	min: number;
	fuelGrade: FuelFuelGrade;
}

interface Identifiable {
	name: string;
	id: string;
}
