import { texts } from "../texts.config";
import { lionUserManager } from './UserRepo'

export const Repo = (state = { claimArea : null , userRoles : null , claimType : null , jobCategories:null , roleWiseCredit:null } , action: { type: ClaimActionTypes, payload: any }) => {
    switch (action.type) {  
        case ClaimActionTypes.GET_CLAIM_AREAS : {
            return { ...state, claimArea : action.payload }
        }   
        case ClaimActionTypes.GET_USER_ROLES : {
            return { ...state, userRoles : action.payload }
        }  
        case ClaimActionTypes.GET_CLAIM_TYPES : {
            return { ...state, claimType : action.payload }
		} 
		case ClaimActionTypes.JOB_CATEGORIES_TYPES : {
            return { ...state, jobCategories : action.payload }
		} 
		case ClaimActionTypes.USER_WISE_CREDITS : {
            return { ...state, roleWiseCredit : action.payload }
        } 
		 
		
        default: return state;
    }
}

export const MedicalClaimRepo = (dispatch: any) => ({
 
    getActiveQuota: async () => {
        try {
         
            const res = await lionUserManager.authenticationManager.get('/clubMembership/admin/getAllQuota');
        
            if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.GET_ACTIVE_QUATA,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },


    getAllClaimAreas: async () => {
        try {
         
            const res = await lionUserManager.authenticationManager.get('/medicalClaim/MedicalClaim/AllNotes');
             
            if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.GET_CLAIM_AREAS,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },


    createClaimArea : async (name : any) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.post('/medicalClaim/admin/createNote' ,  {
                "name": name
            });
            console.log(res); 
            if (res.status == 201) { 
                  response = {
                      "success" : true,
                      "id" : res.data.Id
                  }
                 
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                 response = {
                    "success" : false 
                }
                return response;
            }
        } catch (err) {
            return false;
            throw err;
        }
    },


    claimTypeList : async (name : any) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.get('/medicalClaim/ClaimType/AllList' );
             if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.GET_CLAIM_TYPES,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },


    createClaimType : async (name : any) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.post('/medicalClaim/admin/createClaimType' ,  {
                "name": name
            });
           
            if (res.status == 201) { 
                  response = {
                      "success" : true,
                      "id" : res.data.Id
                  }
                 
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                 response = {
                    "success" : false 
                }
                return response;
            }
        } catch (err) {
            return false;
            throw err;
        }
	},
	createRoleWiseQuata : async (data : any) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.post('/medicalClaim/admin/createUserRoleWiseCreditLimit' , data);
           console.log(res)
            if (res.status == 201) { 
                  response = {
                      "success" : true,
                      "id" : res.data.Id
                  }
                 
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                 response = {
                    "success" : false 
                }
                return response;
            }
        } catch (err) {
            return false;
            throw err;
        }
    },

    getUserRoles: async () => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.get('/user/role/getByModule/2');
            if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.GET_USER_ROLES,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
	},
	
	getJobCategories: async () => {
        try { 
			const res = await lionUserManager.authenticationManager.get('/user/admin/getJobCategory');
			  if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.JOB_CATEGORIES_TYPES,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
	},
	
	getUserWiseCreditLimits: async () => {
        try { 
			const res = await lionUserManager.authenticationManager.get('/medicalClaim/admin/getAllUserRoleWiseCreditLimit');
			 if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.USER_WISE_CREDITS,
                    payload: res.data
                });
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },
  
 
 
 
})

export class ClaimActionTypes {
    static GET_CLAIM_AREAS = 'GET_CLAIM_AREAS'; 
    static GET_USER_ROLES = 'GET_USER_ROLES'; 
    static GET_ACTIVE_QUATA = 'GET_ACTIVE_QUATA'; 
	static GET_CLAIM_TYPES = 'GET_CLAIM_TYPES'; 
	static USER_WISE_CREDITS = 'USER_WISE_CREDITS'; 
	static JOB_CATEGORIES_TYPES = 'JOB_CATEGORIES_TYPES';

}