import React from "react";
import { Table } from "antd";

export interface MasterdataEditTabProps<T> {
	sourceData: T[];
	onDone?: (data: T) => void
}

export interface MasterdataEditTabState<T> {
	item?: T;
}

export class MasterdataEditTab<T> extends React.Component<
	MasterdataEditTabProps<T>,
	MasterdataEditTabState<T>
> {
	constructor(props: any) {
		super(props);
		this.setItem = this.setItem.bind(this);
		this.clearItem = this.clearItem.bind(this);
		this.onDone = this.onDone.bind(this);
		this.state = {};
	}

	onDone(data: T){
		this.props.onDone && this.props.onDone(data)
	}

	setItem(item: T) {
		this.setState({ item });
	}

	clearItem() {
		this.setState({ item: undefined });
	}
}
