import { texts } from "../texts.config";
import { lionUserManager } from './UserRepo'

export const Repo = (state = { CostCenter : null , accountCode : null , sVendor : null , vendor : null  , users : null  } , action: { type: ClaimActionTypes, payload: any }) => {
    switch (action.type) {  
        case ClaimActionTypes.GET_COST_CENTER : {
            return { ...state, CostCenter : action.payload }
        }  
        case ClaimActionTypes.GET_ACCOUNT_CODE : {
            return { ...state, accountCode : action.payload }
        }  
        case ClaimActionTypes.GET_SAP_VENDOR : {
            return { ...state, sVendor : action.payload }
        }  
        case ClaimActionTypes.GET_VENDOR : {
            return { ...state, vendor : action.payload }
        }  
        case ClaimActionTypes.GET_USERS : {
            return { ...state, users : action.payload }
        }  
         
        default: return state;
    }
}

export const PettyCashClaimRepo = (dispatch: any) => ({
 
    GetAllPettyCashCostCenters: async () => {
        try {
         
            const res = await lionUserManager.authenticationManager.get('/esb/Admin/GetAllData');
            console.log("GetAllPettyCashCostCenters " , res);
            if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.GET_COST_CENTER,
                    payload: res.data.PettyCostCenters
                });
                dispatch({
                    type: ClaimActionTypes.GET_ACCOUNT_CODE,
                    payload: res.data.AccountCodes
                });
                dispatch({
                    type: ClaimActionTypes.GET_SAP_VENDOR,
                    payload: res.data.SAPVenddors
                });
                dispatch({
                    type: ClaimActionTypes.GET_VENDOR,
                    payload: res.data.Vendors
                });
               


                
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },


    GetUserNameId : async () => {
        try {
         
            const res = await lionUserManager.authenticationManager.get('/esb/Admin/GetUserNameID'); 
            console.log("GetAllPettyCashCostCenters " , res);
            if (res.status == 200) {    
                dispatch({
                    type: ClaimActionTypes.GET_USERS,
                    payload: res.data
                });  
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },


    SavePettyCostCenter: async (CostCenter : any , Description:any) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.post('/esb/Admin/SavePettyCostCenter' , { "CostCenter" : CostCenter , "Description" : Description , "ProfitCenter" : ""});
            
            if (res.status == 201) {    
                response = {
                    "success" : true, 
                }
                return response;
             } else { 
                response = {
                    "success" : false, 
                }
                return response;
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },


    SaveAccountCode: async (AccountCode : any , Description:any , TaxCode : any) => {
        try {
            var response;
            console.log(AccountCode);
            const res = await lionUserManager.authenticationManager.post('/esb/Admin/SaveAccountCode' , { "accountCode" : AccountCode , "description" : Description , "taxCode" : TaxCode , "isActive" : true});
            
            if (res.status == 201) {    
                response = {
                    "success" : true, 
                }
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                response = {
                    "success" : false, 
                }
                return response;
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },

    SaveVendor: async (VendorName : any , VendorCode:any) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.post('/esb/Admin/SaveVendor' , { "vendorName" : VendorName , "vendorCode" : VendorCode , "ProfitCenter" : ""});
            
            if (res.status == 201) {    
                response = {
                    "success" : true, 
                }
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                response = {
                    "success" : false, 
                }
                return response;
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },

    SaveSapVendor : async (userId : any , sapVenderCode:any) => {
        try {
            var response;
            const res = await lionUserManager.authenticationManager.post('/esb/Admin/SaveSAPVendor' , 
            { "userID" : userId , 
            "sap_Vendor_Code" : sapVenderCode  });
            
            if (res.status == 201) {    
                response = {
                    "success" : true, 
                }
                return response;
             } else { 
                //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
                response = {
                    "success" : false, 
                }
                return response;
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },

     
  
 
 
 
})

export class ClaimActionTypes { ; 
    static GET_COST_CENTER = 'GET_COST_CENTER'; 
    static GET_ACCOUNT_CODE = 'GET_ACCOUNT_CODE';
    static GET_SAP_VENDOR = 'GET_SAP_VENDOR'; 
    static GET_VENDOR = 'GET_VENDOR'; 
    static GET_USERS = 'GET_USERS'; 

}