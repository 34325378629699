import React from "react";
import { MasterdataEditTab } from "../../../components/MasterDataTab";
import { MasterDataFormMode, MasterDataForm } from "../../../components/Form";
import { Button, Input, Form, Select, Table, Alert, message } from "antd";
import { lionUserManager } from "../../../repos/UserRepo";
import { texts } from "../../../texts.config";
import { EditOutlined } from "@ant-design/icons";
import { MeVehicalType } from "../../../repos/MeClaimRepo";

export class MeVehicleTypeTab extends MasterdataEditTab<MeVehicalType> {
	_onModeChange(mode: MasterDataFormMode) {
		super.clearItem();
	}

	async _validateDuplicates(rule: any, value: any) {
		if (this.props.sourceData) {
			if (this.props.sourceData.find(data => data.name === value))
				throw new Error("Vehicle type already exists");
		} else throw new Error("Vehicle types not found");
	}

	render() {
		return (
			<>
				{this.state.item ? (
					<MeVehicalTypeForm
						validators={{}}
						initialValue={this.state.item}
						onModeChange={this._onModeChange.bind(this)}
						onDone={async (data: MeVehicalType) => {
							let _hide = message.loading("Action in progress..", 0);
							try {
								const res = await lionUserManager.authenticationManager.call(
									"put",
									`/meClaim/admin/editVehicalType/${data.id}`,
									{ name: data.name, status: 1 }
								);

								if (res.status == 201) {
									message.success("Done");
									super.onDone(data);
									super.clearItem();
								}
							} catch (err) {
								message.error(texts.DEFAULT_ERROR_MESSAGE);
							} finally {
								_hide();
							}
						}}
						key='me-vehicle-type-edit'
						mode={MasterDataFormMode.EDIT}
					/>
				) : (
					<MeVehicalTypeForm
						validators={{
							check_duplicate: this._validateDuplicates.bind(this)
						}}
						initialValue={{ name: "", id: "" }}
						onDone={async (data: MeVehicalType) => {
							let _hide = message.loading("Action in progress..", 0);
							try {
								const res = await lionUserManager.authenticationManager.post(
									"/meClaim/FuelRegister/createVehicalType",
									{ name: data.name }
								);

								if (res.status == 201) {
									message.success("Done");
									super.onDone(data);
								}
							} catch (err) {
								message.error(texts.DEFAULT_ERROR_MESSAGE);
							} finally {
								_hide();
							}
						}}
						key='me-vehicle-type-create'
						mode={MasterDataFormMode.CREATE}
					/>
				)}
				<Table dataSource={this.props.sourceData}>
					<Table.Column
						title='No'
						key='index'
						render={(value, item, index) => index + 1}
					/>
					<Table.Column title='Category Name ' dataIndex='name' />
					<Table.Column
						title='Action'
						key='action'
						render={(value, item: MeVehicalType, index) => (
							<span>
								{/* <DeleteOutlined style={{ color: "#de2f38" }} /> */}
								<EditOutlined
									style={{ color: "#de2f38", marginLeft: 10 }}
									onClick={() => super.setItem(item)}
								/>
							</span>
						)}
					/>
				</Table>
			</>
		);
	}
}

class MeVehicalTypeForm extends MasterDataForm<MeVehicalType, any> {
	async _onDone() {
		if (this.formRef.current && (await this.validate()))
			super.onDone({
				id: this.props.initialValue.id,
				name: this.formRef.current.getFieldValue("type")
			});
	}

	render() {
		return (
			<>
				{this.props.mode == MasterDataFormMode.EDIT && (
					<Alert
						message='Currently in Edit state.'
						type='info'
						closeText='Exit Now'
						onClose={() => super.changeMode(MasterDataFormMode.CREATE)}
					/>
				)}
				<Form
					// {...layout}
					ref={this.formRef}
					initialValues={{
						type: this.props.initialValue.name
					}}
					onFinish={this._onDone.bind(this)}>
					<Form.Item
						name='type'
						label='Type'
						rules={[
							{
								validator: async (rule, value) => {
									if (this.props.validators["check_duplicate"])
										await this.props.validators["check_duplicate"](rule, value);
								}
							},
							{
								required: true,
								message: "Type is required"
							}
						]}>
						<Input />
					</Form.Item>
					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</>
		);
	}
}
