import React, { RefObject, ReactNode, CSSProperties } from "react";
import { lionUserManager } from "../../../repos/UserRepo";
import {
	Button,
	Tree,
	Row,
	Col,
	Empty,
	Select,
	Form,
	Spin,
	message,
	Card,
	Tabs,
	Input,
	Alert,
	Badge,
	Result,
	Tooltip,
	Popover
} from "antd";
import { DataNode } from "rc-tree/lib/interface";
import FormItem from "antd/lib/form/FormItem";
import {
	FileOutlined,
	FolderOutlined,
	InfoCircleOutlined
} from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import { OptionType } from "antd/lib/select";
import { uniqBy } from "lodash";

interface NameWithId {
	id: string;
	name: string;
}

interface ILionData {
	root: boolean;
	id: string;
	masterData: NameWithId;
	type: NameWithId;
	sub: ILionData[];
}

interface TreeNode extends DataNode {
	children: TreeNode[];
	meta: ILionData;
}

const normalizeMainCat = (data: any[]): ILionData[] =>
	data.map(({ main_cat }, i) => ({
		id: `root_${i}`,
		root: true,
		isLeaf: true,
		sub: [...main_cat],
		type: { ...main_cat[0].type, name: `Main Category` },
		masterData: {
			...main_cat[0].masterData,
			id: "-1",
			name: `${main_cat[0].type.name}`
		}
	}));

const lionDataToTreeData = (data: ILionData): TreeNode => {
	let _isLeaf = data.sub.length === 0;
	return {
		title: data.masterData.name,
		key: data.id.toString(),
		isLeaf: _isLeaf,
		children: _isLeaf
			? []
			: data.sub.map((e: ILionData) => lionDataToTreeData(e)),
		meta: { ...data, sub: [] }
	};
};

const treeToFlatCollection = (tree: TreeNode): TreeNode[] => {
	let collection: TreeNode[] = [];
	const checkEach = (node: TreeNode) => {
		collection.push({ ...node, children: [] });
		if (node.children.length > 0) node.children.forEach(checkEach);
	};
	checkEach(tree);
	return collection;
};

const getMasterDataByCategory = (
	collection: TreeNode[],
	category: Category
): NameWithId[] => {
	return collection
		.filter(node => node.meta.type.id === category.id)
		.map(node => node.meta.masterData);
};

class CategoryCollection {
	static instance = new CategoryCollection();

	list: CategoriesWithMasterDataRef[] = [];

	addCategories(categories: CategoriesWithMasterDataRef) {
		this.list.push(categories);
	}

	getCategoryByMasterData(masterData: DataRecord): CategoriesWithMasterDataRef {
		let _data = this.list.find(e => e.masterData === masterData);

		if (_data) return _data;
		return { categories: [], masterData };
	}

	isExisting(masterData: DataRecord): boolean {
		let _data = this.list.find(e => e.masterData === masterData);

		return !!_data;
	}
}

interface AretworkCategoryTabState {
	value?: TreeNode;
	repo: TreeNode[];
	/// flat version of the repo
	flatRepo: TreeNode[];
	categoriesWithMasterDateRef?: CategoriesWithMasterDataRef;
	isTreeSelectorBusy: boolean;
	isCategoryFetcherBusy: boolean;
}

export class AretworkCategoryTab extends React.Component<
	any,
	AretworkCategoryTabState
> {
	constructor(props: any) {
		super(props);
		this.onTreeNodeSelect = this.onTreeNodeSelect.bind(this);
		this.changeCategoriesByMasterdata = this.changeCategoriesByMasterdata.bind(
			this
		);
		this.getData = this.getData.bind(this);
		this.state = {
			repo: [],
			flatRepo: [],
			isTreeSelectorBusy: false,
			isCategoryFetcherBusy: false
		};
	}

	componentDidMount() {
		this.getData();
	}

	clearValue() {
		this.setState({ value: undefined });
	}

	async onTreeNodeSelect(e: TreeNode) {
		this.setState({
			value: e,
			categoriesWithMasterDateRef: undefined
		});
		await this.changeCategoriesByMasterdata(e.meta.masterData);
	}

	async changeCategoriesByMasterdata(masterData: DataRecord) {
		try {
			this.setState({
				isCategoryFetcherBusy: true
			});
			if (!CategoryCollection.instance.isExisting(masterData)) {
				let data = await MasterDataServie.instance.getCategoriesByMasterData(
					masterData
				);
				CategoryCollection.instance.addCategories({ categories: data, masterData });
			}
		} catch (err) {
			console.log(err);
		} finally {
			this.setState({
				categoriesWithMasterDateRef: CategoryCollection.instance.getCategoryByMasterData(
					masterData
				),
				isCategoryFetcherBusy: false
			});
			console.log(this.state.categoriesWithMasterDateRef);
		}
	}

	async getData() {
		try {
			this.setState({
				isTreeSelectorBusy: true
			});

			const categories = await MasterDataServie.instance.getAllMasterData();

			const _repo = normalizeMainCat(categories).map((e: ILionData) =>
				lionDataToTreeData(e)
			);

			this.setState({
				repo: _repo,
				flatRepo: _repo.flatMap(node => treeToFlatCollection(node))
			});
		} catch (err) {
			message.error("Something went wrong while retrieving key data");
		} finally {
			this.setState({ isTreeSelectorBusy: false });
		}
	}

	render() {
		return (
			<Row className='ant-card ant-card-bordered'>
				<Col span='auto' flex='auto' style={{ borderRight: "1px solid #f0f0f0" }}>
					<Spin size='small' spinning={this.state.isTreeSelectorBusy}>
						<TreeDataSelector
							disabled={this.state.isCategoryFetcherBusy}
							onSelect={this.onTreeNodeSelect}
							dataSource={this.state.repo}
						/>
					</Spin>
				</Col>
				<Col>
					{this.state.value && (
						<Card
							bodyStyle={{ padding: 0 }}
							style={{ minWidth: 400 }}
							title={
								<Row align='middle' gutter={10}>
									<Col style={{ fontSize: 20 }}>
										{this.state.value.isLeaf ? <FileOutlined /> : <FolderOutlined />}
									</Col>
									<Col>
										{/* <Row style={{ fontSize: 12 }}>Master Data</Row> */}
										<Row style={{ fontSize: 12 }}>{this.state.value.meta.type.name}</Row>
										<Row>{this.state.value.meta.masterData.name}</Row>
									</Col>
								</Row>
							}
							bordered={false}>
							<Tabs
								defaultActiveKey='0'
								size='small'
								type='card'
								tabBarStyle={{ padding: "5px 0  0 5px" }}>
								<Tabs.TabPane tab='Add' key='0'>
									<MasterDataAddExistingForm
										node={this.state.value}
										filter={cat => {
											let selectedMasterdata = this.state.value
												? treeToFlatCollection(this.state.value)
												: [];

											return uniqBy(
												getMasterDataByCategory(this.state.flatRepo, cat).filter(
													value =>
														!selectedMasterdata.find(node => node.meta.masterData.id === value.id)
												),
												// getMasterDataByCategory(this.state.flatRepo, cat),
												value => value.id
											);
										}}
										isCategoryFetcherBusy={this.state.isCategoryFetcherBusy}
										categoriesWithMasterDataRef={this.state.categoriesWithMasterDateRef}
										onDone={async () => await this.getData()}
									/>
								</Tabs.TabPane>
								<Tabs.TabPane tab='Create' key='1'>
									<MasterDataCreateForm
										node={this.state.value}
										isCategoryFetcherBusy={this.state.isCategoryFetcherBusy}
										categoriesWithMasterDataRef={this.state.categoriesWithMasterDateRef}
										onDone={async () => await this.getData()}
									/>
								</Tabs.TabPane>
								<Tabs.TabPane tab='Edit' key='2'>
									<MasterDataEditForm
										node={this.state.value}
										onDone={async () => await this.getData()}
									/>
								</Tabs.TabPane>
								<Tabs.TabPane
									tab='Delete'
									key='3'
									disabled={
										this.state.value && this.state.value.meta.masterData.id === "-1"
									}>
									<MasterDataDeleteForm
										node={this.state.value}
										onDone={async () => {
											this.clearValue();
											await this.getData();
										}}
									/>
								</Tabs.TabPane>
							</Tabs>
						</Card>
					)}
				</Col>
			</Row>
		);
	}
}

interface DataSelectorProps {
	disabled?: boolean;
	onSelect: (node: TreeNode) => void;
	dataSource: DataNode[];
}

class TreeDataSelector extends React.Component<DataSelectorProps, any> {
	constructor(props: any) {
		super(props);
	}

	_onChange(id: any, info: any) {
		// this.props.onSelect(info);
	}

	render() {
		if (this.props.dataSource.length > 0)
			return (
				<Tree.DirectoryTree
					disabled={this.props.disabled}
					height={500}
					defaultExpandAll
					expandAction='doubleClick'
					treeData={this.props.dataSource}
					onSelect={(i: any, info: any) =>
						this.props.onSelect(info.selectedNodes[0] as TreeNode)
					}
				/>
			);
		return <Empty />;
	}
}

interface DataRecord {
	id: string;
}

interface MasteDataArgs {
	name: string;
	code: string;
}

interface CategoriesWithMasterDataRef {
	categories: Category[];
	masterData: DataRecord;
}

interface Category extends DataRecord {
	name: string;
}

interface CategoryArgs {
	name: string;
	orderIndex: string;
	masterCategory: { id: string } | null;
}

interface CategoryMasterDataArgs {
	category: DataRecord;
	parent: DataRecord | null;
	child: DataRecord | null;
}

class MasterDataServie {
	static instance = new MasterDataServie();

	async editMasterdata(data: MasteDataArgs, id: string): Promise<string> {
		const res = await lionUserManager.authenticationManager.call(
			"put",
			`/artwork/admin/editMasterData/${id}`,
			data
		);

		if (res.status == 200) {
			return "OK";
		} else throw new Error();
	}

	async deleteMasterdata(mId: string, cID: string): Promise<string> {
		const res = await lionUserManager.authenticationManager.call(
			"put",
			`/artwork/admin/deleteCat/${mId}/${cID}`,
			{}
		);

		if (res.status == 200) {
			return "OK";
		} else throw new Error();
	}

	async createCategory(data: CategoryArgs): Promise<DataRecord> {
		const res = await lionUserManager.authenticationManager.post(
			"/artwork/admin/createCategory",
			data
		);

		if (res.status == 201 && res.data) {
			return { id: res.data };
		} else throw new Error();
	}

	async getCategoriesByMasterData(data: DataRecord): Promise<Category[]> {
		const res = await lionUserManager.authenticationManager.get(
			`/artwork/category/getsub/${data.id}`
		);

		if (res.status == 200) {
			return res.data;
		} else throw new Error();
	}

	async getAllMasterData(): Promise<ILionData[]> {
		const res = await lionUserManager.authenticationManager.get(
			"/artwork/category/get"
		);

		if (res.status == 200) {
			return res.data;
		} else throw new Error();
	}

	async createMasteData(data: MasteDataArgs): Promise<DataRecord> { 
		{
			const res = await lionUserManager.authenticationManager.post(
			"/artwork/admin/createMasteData",
			data
		);

		if (res.status == 201) {
			return res.data;
		} else throw new Error();}
	}

	async createCategoryMasterData(
		data: CategoryMasterDataArgs
	): Promise<boolean> {
		console.log({
			category: data.category,
			masterCategoryMasterDataId: data.parent,
			masterData: data.child,
			isActive: 1
		})
		const res = await lionUserManager.authenticationManager.post(
			"/artwork/admin/createCategoryMasterData",
			{
				category: data.category,
				masterCategoryMasterDataId: data.parent,
				masterData: data.child,
				isActive: 1
			}
		);

		const _is = res.status == 201;
		if (_is) {
			return _is;
		} else throw new Error();
	}

	async createApproveDetail (body : any): Promise<boolean> {
		const res = await lionUserManager.authenticationManager.post(
			"/artwork/admin/createApproveDetail",
			body
		);

		const _is = res.status == 201;
		if (_is) {
			return _is;
		} else throw new Error();
	}
}

interface MasterDataFormProps<T> {
	node: TreeNode;
	onDone: (data: T) => void;
}

interface MasterDataFormState {
	errors: string[];
	isBusy: boolean;
}

abstract class MasterDataForm<
	P extends MasterDataFormProps<any>,
	S extends MasterDataFormState
> extends React.Component<P, S> {
	formRef: RefObject<FormInstance> = React.createRef<FormInstance>();
	layout: any | CSSProperties;
	id: string;

	constructor(props: any) {
		super(props);
		this.id = "";
		this.layout = {
			labelCol: {
				span: 8
			},
			wrapperCol: {
				span: 16
			}
		};
		this.state = { errors: [] } as any;
		this.renderFormItems = this.renderFormItems.bind(this);
		this.onFinish = this.onFinish.bind(this);
	}

	abstract onFinish(): Promise<void>;

	abstract renderFormItems(): ReactNode;

	addError(err: string) {
		this.setState({
			errors: [...this.state.errors, err]
		});
	}

	clearErrors() {
		this.setState({
			errors: []
		});
	}

	get defaultErrorMessage() {
		return `Request can't be handled!`;
	}

	render() {
		return (
			<>
				{this.state.errors.map(err => (
					<Alert type='error' closable message={err} banner />
				))}
				<Form
					id={this.id}
					{...this.layout}
					ref={this.formRef}
					onFinish={this.onFinish}
					style={{ padding: 5 }}>
					{this.renderFormItems()}
				</Form>
			</>
		);
	}
}

interface MasterDataCreateFormProps extends MasterDataFormProps<DataRecord> {
	isCategoryFetcherBusy: boolean;
	categoriesWithMasterDataRef?: CategoriesWithMasterDataRef;
}

class MasterDataCreateForm extends MasterDataForm<
	MasterDataCreateFormProps,
	MasterDataFormState
> {
	constructor(props: any) {
		super(props);
		this.id = "MasterDataCreateForm";
		this.layout = { ...this.layout, align: "end" };
	}

	async onFinish() {
		this.setState({ isBusy: true });
		let _hide = message.loading("Action in progress..", 0);
		try {
			if (
				this.props.node &&
				this.formRef.current &&
				await this.formRef.current.validateFields()
			) {
				let _categoryId = this.formRef.current.getFieldValue("cat");

				if (this.props.categoriesWithMasterDataRef) {
					let _parent = !!!this.props.node.meta.root
						? this.props.node.meta.masterData
						: null;

					if (this.props.node.meta.masterData.id === "-1")
						_categoryId = this.props.node.meta.type.id;

					const _child = await MasterDataServie.instance.createMasteData({
						name: this.formRef.current.getFieldValue("name"),
						code: this.formRef.current.getFieldValue("code")
					});

					await MasterDataServie.instance.createCategoryMasterData({
						parent: _parent,
						child: _child,
						category: { id: _categoryId }
					});

					await MasterDataServie.instance.createApproveDetail(
						{
							description :"Level 1 for " + this.formRef.current.getFieldValue("name"),
							masterApprovalLevel :{"id":1},
							masterData: [_child],
							approvalLevelRoles : [
								{   roleName: _categoryId == 1 ? "Premium Head" : "Head of Marketing",
									roleId:  _categoryId == 1 ? 32 : 7,
								}
								
							]
						}
					);

					message.success("Successfully Created!");		
					this.formRef.current.resetFields();
					this.props.onDone({ id: "" });
					this.clearErrors();
				}
			}
		} catch (err) {
			this.addError(this.defaultErrorMessage);
		} finally {
			_hide();
			this.setState({ isBusy: false });
		}
	}

	componentWillReceiveProps() {
		if (this.formRef.current) this.formRef.current.resetFields(["cat"]);
	}

	renderFormItems() {
		return (
			<>
				<Form.Item
					name='name'
					label='Identifier'
					rules={[
						{ required: true, message: "Master data identifier is required" }
					]}>
					<Input
						suffix={
							<Popover
								// title='Extra information'
								content={
									<p>
										The "<b>Identifier</b>" property is used to identify individual master
										data.
									</p>
								}>
								<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
							</Popover>
						}
					/>
				</Form.Item>
				<Form.Item
					name='code'
					label='Code'
					rules={[{ required: true, message: "Master data code is required" }]}>
					<Input
						suffix={
							<Popover
								// title='Extra information'
								content={
									<p>
										The "<b>Code</b>" property is used to generate the artwork name.
									</p>
								}>
								<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
							</Popover>
						}
					/>
				</Form.Item>
				<Form.Item
					name='cat'
					label='Category'
					rules={[
						{
							required: this.props.node.meta.masterData.id !== "-1",
							message: "Master data category is required"
						}
					]}>
					<Select
						loading={this.props.isCategoryFetcherBusy}
						disabled={
							this.props.categoriesWithMasterDataRef &&
							this.props.categoriesWithMasterDataRef.categories.length === 0
						}>
						{this.props.categoriesWithMasterDataRef &&
							this.props.categoriesWithMasterDataRef.categories.map(e => (
								<Select.Option value={e.id}>{e.name}</Select.Option>
							))}
					</Select>
				</Form.Item>
				<Button type='primary' htmlType='submit' loading={this.state.isBusy}>
					Create
				</Button>
			</>
		);
	}
}

class MasterDataEditForm extends MasterDataForm<
	MasterDataFormProps<void>,
	MasterDataFormState
> {
	constructor(props: any) {
		super(props);
		this.id = "MasterDataEditForm";
		this.layout = { ...this.layout, align: "end" };
	}

	async onFinish() {
		this.setState({ isBusy: true });
		try {
			if (
				this.props.node &&
				this.formRef.current &&
				await this.formRef.current.validateFields()
			) {
				await MasterDataServie.instance.editMasterdata(
					{
						name: this.formRef.current.getFieldValue("name"),
						code: this.formRef.current.getFieldValue("code")
					},
					this.props.node.meta.masterData.id
				);

				this.formRef.current.resetFields();
				this.props.onDone();
				this.clearErrors();
			}
		} catch (err) {
			this.addError(this.defaultErrorMessage);
		} finally {
			this.setState({ isBusy: false });
		}
	}

	renderFormItems() {
		return (
			<>
				<Form.Item
					name='name'
					label='Identifier'
					rules={[
						{ required: true, message: "Master data identifier is required" }
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					name='code'
					label='Code'
					rules={[{ required: true, message: "Master data code is required" }]}>
					<Input />
				</Form.Item>
				<Button type='primary' htmlType='submit' loading={this.state.isBusy}>
					Edit
				</Button>
			</>
		);
	}
}

interface MasterDataDeleteFormState extends MasterDataFormState {
	conf: string;
}

class MasterDataDeleteForm extends MasterDataForm<
	MasterDataFormProps<void>,
	MasterDataDeleteFormState
> {
	constructor(props: any) {
		super(props);
		this.id = "MasterDataDeleteForm";
		this.state = { ...this.state, conf: "" };
	}

	async onFinish() {
		this.setState({ isBusy: true });
		try {
			if (
				this.props.node &&
				this.formRef.current &&
				await this.formRef.current.validateFields()
			) {
				await MasterDataServie.instance.deleteMasterdata(
					this.props.node.meta.masterData.id,
					this.props.node.meta.type.id
				);

				this.formRef.current.resetFields();
				this.props.onDone();
				this.setState({ conf: "" });
				this.clearErrors();
			}
		} catch (err) {
			this.addError(this.defaultErrorMessage);
		} finally {
			this.setState({ isBusy: false });
		}
	}

	renderFormItems() {
		return (
			<>
				<Row justify='center' align='middle'>
					<Col flex='auto' style={{ textAlign: "center" }}>
						<p style={{ color: "red", fontSize: 15 }}>
							<b>WARNING! This cannot be undone</b>
						</p>
						<p>
							Please type "<b>{this.props.node.meta.masterData.name}</b>" to confirm.
						</p>
					</Col>
				</Row>
				<Row justify='center' align='middle' style={{ marginTop: 20 }} gutter={5}>
					<Col>
						<Input
							value={this.state.conf}
							onChange={({ target }) => this.setState({ conf: target.value })}
						/>
					</Col>
					<Col>
						<Button
							type='danger'
							htmlType='submit'
							loading={this.state.isBusy}
							disabled={this.state.conf !== this.props.node.meta.masterData.name}>
							Delete
						</Button>
					</Col>
				</Row>
			</>
		);
	}
}

interface MasterDataAddExistingFormProps
	extends MasterDataFormProps<DataRecord> {
	isCategoryFetcherBusy: boolean;
	categoriesWithMasterDataRef?: CategoriesWithMasterDataRef;
	filter: (category: Category) => NameWithId[];
}

interface MasterDataAddExistingState extends MasterDataFormState {
	masterData?: NameWithId[];
}

class MasterDataAddExistingForm extends MasterDataForm<
	MasterDataAddExistingFormProps,
	MasterDataAddExistingState
> {
	constructor(props: any) {
		super(props);
		this.id = "MasterDataAddExistingForm";
		this.layout = { ...this.layout, align: "end" };
	}

	componentWillReceiveProps() {
		if (this.formRef.current) {
			this.formRef.current.resetFields(["cat"]);
			this.formRef.current.resetFields(["mdata"]);
		}
		this.setState({
			masterData: []
		});
	}

	async onFinish() {
		this.setState({ isBusy: true });
		try {
			if (
				this.props.node &&
				this.formRef.current &&
				await this.formRef.current.validateFields()
			) {
				let _masterDataId = this.formRef.current.getFieldValue("mdata");
				let _catId = this.formRef.current.getFieldValue("cat");

				await MasterDataServie.instance.createCategoryMasterData({
					parent: this.props.node.meta.masterData,
					child: { id: _masterDataId },
					category: { id: _catId }
				});

				this.formRef.current.resetFields();
				this.props.onDone({ id: "" });
				this.clearErrors();
			}
		} catch (err) {
			this.addError(this.defaultErrorMessage);
		} finally {
			this.setState({ isBusy: false });
		}
	}

	renderFormItems() {
		return (
			<>
				<Form.Item
					name='cat'
					label='Category'
					rules={[
						{
							required: this.props.node.meta.masterData.id !== "-1",
							message: "Master data category is required"
						}
					]}>
					<Select
						loading={this.props.isCategoryFetcherBusy}
						onChange={(_, option: any) =>
							this.setState({
								masterData: this.props.filter({ id: option.value, name: "" })
							})
						}
						disabled={
							this.props.categoriesWithMasterDataRef &&
							this.props.categoriesWithMasterDataRef.categories.length === 0
						}>
						{this.props.categoriesWithMasterDataRef &&
							this.props.categoriesWithMasterDataRef.categories.map(e => (
								<Select.Option value={e.id}>{e.name}</Select.Option>
							))}
					</Select>
				</Form.Item>
				<Form.Item
					name='mdata'
					label='Data'
					rules={[
						{
							required: true,
							message: "Master data is required"
						}
					]}>
					<Select disabled={!!!this.state.masterData}>
						{this.state.masterData &&
							this.state.masterData.map(e => (
								<Select.Option value={e.id}>{e.name}</Select.Option>
							))}
					</Select>
				</Form.Item>
				<Button type='primary' htmlType='submit' loading={this.state.isBusy}>
					Add
				</Button>
			</>
		);
	}
}
