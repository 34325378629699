import React, { FunctionComponent } from 'react';
import { Descriptions, Button , Row ,Spin, Col ,   Select,  Table , Divider,message , Tag,   } from 'antd';
 
import { connect } from 'react-redux';
 
import { EditOutlined, FundViewOutlined } from '@ant-design/icons';
import { UserManagementRepo } from '../../repos/UserManagementRepo';
import '../style.css';
import { Tabs  } from 'antd';

const { TabPane } = Tabs;
const { Option } = Select;
const { Column } = Table;
const moment = require('moment');
 
  
  
var user_data : any;
var data : any;

class UserView extends React.Component<any, any> { 
    constructor(props: any) {
        super(props); 
        this.state = { 
		  loading : false,
		  user_data:{},
		  userPermitions:[],
		  fuelGrade:{},
		  fuelGradeList : [],
		  fuel_role_status: false
        }
    }

    async componentDidMount() {
		 user_data  = localStorage.getItem('data');
	   this.setState({
		loading : true,
		user_data : JSON.parse(user_data)
      },async ()=>{
		 await this.props.getUserDetaiById(JSON.parse(user_data).id); 
		  await this.props.getAllFuelGrades();   
		var res =  await this.props.getFuelGradeOfUser({"empId": JSON.parse(user_data).id});   
		console.log("res" , res.data)
		var fuel_data = this.props.UserManagementRepo.userDetail.userPermitions.filter((element : any) => {
			return element.id === 3;
		  })[0]; 
		//   console.log(fuel_data)
		this.setState({
		  loading : false,
		  userPermitions:this.props.UserManagementRepo.userDetail.userPermitions,
		  fuelGradeList : res.data,
		  fuel_role_status: fuel_data.roles.length > 0 ? true : false,
		})
	  });   
 
	   
     }
 
    getMasterDataView(){
        return null;
   
  }

  addNewRole = (e : any , index : any) =>{
	 const { allPermitions , userPermitions } = this.props.UserManagementRepo.userDetail;
	  var selected_data =   allPermitions[index].roles[e];
	  
	  var check = userPermitions[index].roles.filter((element : any) => {
		return element.id === selected_data.id;
	  }) 
	  if(check.length == 0){
		userPermitions[index].roles.push(selected_data);
		this.setState({
		  userPermitions :  userPermitions
	   })
 
	}
	else{
		message.error("You Cannot Assign This Role")
	}
	  
  }

  addNewAuthority = (e : any , index : any) =>{
	const { allPermitions , userPermitions } = this.props.UserManagementRepo.userDetail;
	
	var selected_data =  allPermitions[index].authorities[e];
	var check = userPermitions[index].authorities.filter((element : any) => {
		return element.id === selected_data.id;
	  }) 
	  if(check.length == 0){
		userPermitions[index].authorities.push(selected_data);
			this.setState({
			userPermitions :  userPermitions
		})
	}
	else{
		message.error("You Cannot Assign This Authority")
	}
}


selectFuelGrade = (e : any , index : any) =>{ 
 	this.setState({
		fuelGrade : this.props.UserManagementRepo.fuelGrades.filter((data : any)=>{
				return data.id == e
		})[0]
	})
}

  

  getAllPermissions(){
	return this.props.UserManagementRepo.userDetail.allPermitions[1]
	
  }

  checkAuthority = (roles : any ,authority_name : any , index : any) =>{
 	var status = false;
	roles.forEach(function(entry : any) { 
		var checkRole = entry.authorities.filter((authority : any) => { 
			return authority.name === authority_name
		}); 
		if(checkRole.length>0){
			status = true;
		}
	})
	  return status;
  }

  removeRole = ( index: any , itemIndex:any)=>{
	var array = [...this.state.userPermitions]; 
    array[index].roles.splice(itemIndex, 1); 
    this.setState({userPermitions: array});
 
  }

  removeAuthority = ( index: any , itemIndex:any)=>{
	var array = [...this.state.userPermitions]; 
    array[index].authorities.splice(itemIndex, 1); 
    this.setState({userPermitions: array});
 
  }

  editUser = async() =>{
	this.setState({
		loading : true});
	  var data = {
		"userId":JSON.parse(user_data).id,
		"userPermitions":this.state.userPermitions
	  }
	  var response = await this.props.editUserData(data);  
	  if (response.success) {
		window.location.reload();
	}
  }

  addFuleGrade = async()=>{
	this.setState({
		loading : true});
	  var data = {
		"employeeId":JSON.parse(user_data).id, 
		"status":1,
		"fuelGrade":{ "id": this.state.fuelGrade.id }
	  }
	  var response = await this.props.AddFualGrade(data);  
	  if (response) {
		 window.location.reload();
	}
  }


  removeFualGrade = async(fuelGrade : any)=>{
	this.setState({ loading : true});
	   var response = await this.props.removeFuelGradeOfUser(fuelGrade.id);  
	   console.log(response)
	 
	  if (response) {
	     window.location.reload();
	}
  }


   render() {
	   const { user_data , userPermitions } = this.state;
	   const { UserManagementRepo } = this.props;
        return (
            <div>
              <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
			   
                {/* <Tabs defaultActiveKey="1" type="card" size={'small'}>
                    <TabPane tab="Loan Amount" key="1">
					<h5>View History</h5> */}
                     { user_data.userDetails != undefined  && <Descriptions
							title={user_data.userDetails.firstName +" "+ user_data.userDetails.lastName }
							bordered
							column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
						>
							<Descriptions.Item label="Full Name">{user_data.userDetails.fullName}</Descriptions.Item>
							<Descriptions.Item label="Employee Number">{user_data.userDetails.employeeNumber}</Descriptions.Item>
							<Descriptions.Item label="Email">{user_data.userDetails.email}</Descriptions.Item>
							<Descriptions.Item label="Designation">{user_data.userDetails.designationName}</Descriptions.Item>
							<Descriptions.Item label="NIC">{user_data.userDetails.nicNumber}</Descriptions.Item>
							<Descriptions.Item label="MObile">{user_data.userDetails.staffPhone}</Descriptions.Item>
							<Descriptions.Item label="Employment Type">{user_data.userDetails.employmentType}</Descriptions.Item>
							<Descriptions.Item label="DOB">{user_data.userDetails.dateOfBirth.split("T")[0]}</Descriptions.Item>
							<Descriptions.Item label="Extension">{user_data.userDetails.extension}</Descriptions.Item>
							<Descriptions.Item label="Text Card Number">{user_data.userDetails.textCardNumber}</Descriptions.Item>
							<Descriptions.Item label="Date Of Appointment">{user_data.userDetails.dateOfAppointment.split("T")[0]}</Descriptions.Item>
							<Descriptions.Item label="Grade">{user_data.userDetails.gradeName}</Descriptions.Item>
							
						</Descriptions>}
					{/* </TabPane>
					
					
                    
                     
                </Tabs> */}
				{ userPermitions &&  userPermitions.map((data : any , index:any)=>{
                      return  <div style={{marginTop:20 , padding:20 , border: '1px solid #d6d6d6',borderRadius: '5px!important' }}>
					               <h3>{data.name}</h3>
								   <Row>
									<Col span={12} >
									<h4 style={{marginTop:15 , marginBottom:15}}>Add New Roles </h4>
									<Row> 
										<Col span={6} >
											<Select
												showSearch
												style={{ width: 200 }}
												placeholder="Search to Select"
												optionFilterProp="children"
												onChange={(e)=> this.addNewRole(e , index)}
											>
												{UserManagementRepo.userDetail.allPermitions[index].roles.map((role:any , i : any)=>{
												  return <Option value={i}>{role.name}</Option>
												})} 
											</Select></Col>
										<Col span={5} ></Col>
									 </Row>
										
									{data.roles.length > 0 && <h4 style={{marginTop:15 , marginBottom:15}}>Assigned Roles </h4>}
										<Row>{
										data.roles.map((role:any , i : any)=>{
                                              return <Button onClick={() => this.removeRole(index , i)} style={{margin: 1}} type="dashed" danger>{role.name}</Button>
										})
									}</Row>
									

									
									{data.id == 3 && this.state.fuel_role_status  &&	 
									<div style={{marginTop:50}}>
									<h4>Fuel Grade</h4>
									<Col span={11} >
											<Select
												showSearch
												style={{ width: 200 }}
												placeholder="Search to Select"
												optionFilterProp="children"
												onChange={(e)=> this.selectFuelGrade(e , index)}
											>
												{this.props.UserManagementRepo.fuelGrades.map((fuelGrades:any , i : any)=>{
												  return <Option value={fuelGrades.id}>{fuelGrades.name}</Option>
												})} 
											</Select></Col>
											{Object.keys(this.state.fuelGrade).length != 0 &&  <>
												<Tag
												closable 
												style={{marginTop:20}}
												onClose={() => this.setState({ visible: false })}
												>
												{this.state.fuelGrade.name}
												</Tag>
												<Button size="small" onClick={() => this.addFuleGrade()}>
												   ADD
												</Button>
											</> }
											<Row>
											{this.state.fuelGradeList.map((fuelGrade:any , i : any)=>{
												return <Tag
												closable 
												color="error"
												style={{marginTop:20}}
												onClose={() => this.removeFualGrade(fuelGrade)}
												>
												{fuelGrade.fuelGrade.name}
												</Tag>
												 
											})
										}</Row>
											</div>}

 
									</Col>
									<Col span={1}> <Divider type="vertical"  ></Divider> </Col>
								    <Col span={11} >
									<h4 style={{marginTop:15 , marginBottom:15}}>Add New Authority </h4>
									<Row> 
										<Col span={11} >
											<Select
												showSearch
												style={{ width: 200 }}
												placeholder="Search to Select"
												optionFilterProp="children"
												onChange={(e)=> this.addNewAuthority(e , index)}
											>
												{UserManagementRepo.userDetail.allPermitions[index].authorities.map((authority:any , i : any)=>{
												  return <Option value={i}>{authority.name}</Option>
												})} 
											</Select></Col>
										<Col span={5} ></Col>
									 </Row>
										
									 <h4 style={{marginTop:15 , marginBottom:15}}>Assign Authorities </h4>
										<Row>
										{data.authorities.map((authority:any , i : any)=>{
											return <Button onClick={() => this.removeAuthority(index , i)} disabled={this.checkAuthority(data.roles , authority.name , index ) ? true : false} style={{margin:1   }} type="dashed" danger>{authority.name}</Button>
										})
									}</Row></Col>
								   </Row>
					         </div>
				})}
               </Spin>
               {/* <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                </Spin> */}
				<Button onClick={() => this.editUser()}  style={{margin:1   }}  danger>SUBMIT</Button>
            </div>
        )
    }
}


const WrappedUserManagement = UserView;
const withUserManagementRepo = connect(((state: any) => state), UserManagementRepo)(UserView);

export default withUserManagementRepo;

 