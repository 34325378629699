import { Repo as UserRepo } from './UserRepo';
import { Repo as DashboardRepo } from './DashboardRepo';
import { Repo as ClaimRepo } from './ClaimRepo';
import { Repo as MedicalClaimRepo } from './MedicalClaimRepo';
import { Repo as PettyCashClaimRepo } from './PettyCashClaimRepo';
import { Repo as MeClaimRepo } from './MeClaimRepo';
import { Repo as FuelClaimRepo } from './FuelClaimRepo';
import { Repo as StaffLoanRepo } from './StaffLoanRepo';
import { Repo as UserManagementRepo } from './UserManagementRepo';

import { combineReducers, applyMiddleware, createStore } from 'redux';
import 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
    createStateSyncMiddleware,
    Config as ReactStateSyncConfig,
    withReduxStateSync,
    initStateWithPrevTab
} from 'redux-state-sync';


const config: ReactStateSyncConfig = {
    // blacklist: ["RESET"]
}

const repos = {
    UserRepo,
    DashboardRepo,
    ClaimRepo,
    MedicalClaimRepo,
    PettyCashClaimRepo,
    MeClaimRepo,
	FuelClaimRepo,
	StaffLoanRepo,
	UserManagementRepo
}

const persistConfig = {
    key: 'root',
    storage,
}
// const persistedReducer = persistReducer(persistConfig, combineReducers(repos));

const createStoreWithMiddleware = applyMiddleware(createStateSyncMiddleware(config))(createStore);
const store = createStoreWithMiddleware(withReduxStateSync(combineReducers(repos) as { state: number } | any));

// const persistor = persistStore(store)

export const getStore = () => {
    initStateWithPrevTab(store)
    return store;
}