import React, { FunctionComponent } from 'react';
import { Empty, Divider, Button, Typography,message, Row,Input, Avatar, Col, Badge ,Radio, Modal ,Spin, Form ,   Select,  Table } from 'antd';
 
import { connect } from 'react-redux';
 
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { StaffLoanRepo } from '../repos/StaffLoanRepo';
import './style.css';
import { Tabs  } from 'antd';

const { TabPane } = Tabs;
const { Option } = Select;
const { Column } = Table;
const moment = require('moment');
 
  const layout = {
    labelCol: {
      span:5,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 5,
      span: 16,
    },
  };
  
  
 
class StaffLoan extends React.Component<any, any> { 
    constructor(props: any) {
        super(props); 
        this.state = {
          amount : "" , amountError : false , amountErrorMsg : "",
          role : "" , roleName : "" , roleError : false , roleErrorMsg : "",
          loading : false
        }
    }

    async componentDidMount() {
      this.setState({
        loading : true
      });
      await this.props.getLoanDetails();
      await this.props.getUserRoles(); 
      this.setState({
        loading : false
	  });
	  
	  console.log("--" ,this.props.StaffLoanRepo.loanDetails)
     }

     
    onChangeAmount = (e: any) => {
     var _amount = e.target.value.replace(",", "");

      var regex = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
      var checkValue = _amount.match(regex);
      if (checkValue == null &&   _amount != "") {
        this.setState({
          amount: "", amountError : true , amountErrorMsg : "* Invalid Amount"
      });
         //message.error("Enter Valid Amount. For example: Rs .523.36");
         return;
    }
    else{
      this.setState({
        amount: _amount ,  amountError : false , amountErrorMsg : ""
    });
    }

   
 
  };

  editDetail = (e : any)=>{
       console.log(e)
  }

   

    
     submitAmount = async () => { 
       if(this.state.loanAmount == "" || this.state.rate == "" ||  this.state.period == "" || this.state.installment == "" ){
        message.error("All fields are required")
       }
      else{
		const form = new FormData();
		form.append("loanAmount", this.state.loanAmount);
		form.append("rate", this.state.rate);
		form.append("period", this.state.period);
		form.append("installment", this.state.installment);

		console.log(form)
         var response =   await this.props.CreateNewQuata(form);
         if (response.success) {
             window.location.reload();
         }
       }
        
	 }
	 

	 setStateFromInput = (event : any) => {
	    var name : any = event.target.name;
		var obj : any = {};
		obj[name] = event.target.value; 
		this.setState(obj);
	}
    
    getMasterDataView(){
        return <Row>
        <Col span={14}>
            <Table pagination={{ pageSize: 4 }}  style={{ marginTop: 15, padding: 5 }} dataSource={this.props.StaffLoanRepo.loanDetails  } > 
                <Column title="" key="index" render={(value, item, index) => ( index + 1  )}  />
                <Column title="Amount" dataIndex="amount" key="amount" render={(value, item , index) => ((Math.round(value * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}/> 
				<Column title="Discription" dataIndex="discription" key="discription" />
            </Table>
           
        </Col>
           <Divider type="vertical" style={{height:350 , width:10, marginLeft:14}}></Divider>
        <Col span={9} >
          <h3 style={{margin:10}}>Create Quata</h3>
        <Form {...layout}  name="control-hooks"  >
               
                
                <Form.Item 
                    label="* Amount"
                    
                >
                   <Input type="text" name="loanAmount" onChange={this.setStateFromInput}  />
                  {this.state.amountError && <p style={{color:"#d9363e" , fontSize:9}}>{this.state.amountErrorMsg}</p>}
                </Form.Item>

				<Form.Item 
                    label="* Interest Rate"
                    
                >
                   <Input type="number" name="rate" onChange={this.setStateFromInput}  />
                  {this.state.amountError && <p style={{color:"#d9363e" , fontSize:9}}>{this.state.amountErrorMsg}</p>}
                </Form.Item>

				<Form.Item 
                    label="* Period(M)"
                    
                >
                   <Input type="number" name="period" onChange={this.setStateFromInput}  />
                  {this.state.amountError && <p style={{color:"#d9363e" , fontSize:9}}>{this.state.amountErrorMsg}</p>}
                </Form.Item>

				<Form.Item 
                    label="* Installment"
                    
                >
                   <Input type="number" name="installment" onChange={this.setStateFromInput} />
                  {this.state.amountError && <p style={{color:"#d9363e" , fontSize:9}}>{this.state.amountErrorMsg}</p>}
                </Form.Item>
                 
                <Form.Item {...tailLayout} >
                    <Button type="primary" onClick={this.submitAmount}>
                    Submit
                    </Button>
                    
                </Form.Item>
                </Form>

        </Col>
    </Row>
   
  }

  getData(){
    return <Row>
     
</Row>

}


   render() {

        return (
            <div>
              <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
			   
                <Tabs defaultActiveKey="1" type="card" size={'small'}>
                    <TabPane tab="Loan Amount" key="1">
					<h5>View History</h5>
                    {this.getMasterDataView()}
                    </TabPane>
                    
                     
                </Tabs>
               </Spin>
               {/* <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                </Spin> */}
            </div>
        )
    }
}


const WrappedStaffLoan = StaffLoan;
const withClubMembershipClaimRepo = connect(((state: any) => state), StaffLoanRepo)(StaffLoan);

export default withClubMembershipClaimRepo;

 