import React from "react";
import { RefObject } from "react";
import { FormInstance } from "antd/lib/form";

export enum MasterDataFormMode {
	EDIT,
	CREATE
}

export interface MasterDataFormProps<T> {
	mode: MasterDataFormMode;
	initialValue: T;
	onDone: (data: T) => Promise<boolean | void>;
	onModeChange?: (mode: MasterDataFormMode) => void;
	validators?: any;
}

export class MasterDataForm<T, S> extends React.Component<
	MasterDataFormProps<T>,
	S
> {
	formRef: RefObject<FormInstance> = React.createRef<FormInstance>();

	async validate(): Promise<boolean> {
		if (this.formRef.current)
			if (await this.formRef.current.validateFields()) return true;

		return false;
	}

	async onDone(data: T) {
		try {
			await this.props.onDone(data);
			if (this.formRef.current) this.formRef.current.resetFields();
		} catch (err) {}
	}

	changeMode(mode: MasterDataFormMode) {
		this.props.onModeChange && this.props.onModeChange(mode);
	}

	constructor(props: any) {
		super(props);
		this.validate = this.validate.bind(this);
		this.onDone = this.onDone.bind(this);
		this.changeMode = this.changeMode.bind(this);
	}
}
