import React from "react";
import { MasterdataEditTab } from "../../../components/MasterDataTab";
import { MasterDataFormMode, MasterDataForm } from "../../../components/Form";
import {
	Button,
	Input,
	Form,
	Select,
	Table,
	Alert,
	message,
	InputNumber
} from "antd";
import { lionUserManager } from "../../../repos/UserRepo";
import { texts } from "../../../texts.config";
import { EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import {
	MeFuelGrade,
	MeClaimRepoActions,
	MeFuelType
} from "../../../repos/MeClaimRepo";

export class MeFuelGradeTab extends MasterdataEditTab<MeFuelGrade> {
	_onModeChange(mode: MasterDataFormMode) {
		super.clearItem();
	}

	async _validateDuplicates(rule: any, value: any) {
		if (this.props.sourceData) {
			if (this.props.sourceData.find(data => data.name === value))
				throw new Error("Vehicle make already exists");
		} else throw new Error("Vehicle makes not found");
	}

	render() {
		return (
			<>
				{this.state.item ? (
					<MeFuelGradeFormWithRepo
						initialValue={this.state.item}
						validators={{}}
						onModeChange={this._onModeChange.bind(this)}
						onDone={async (data: MeFuelGrade) => {
							console.log(data)
							let _hide = message.loading("Action in progress..", 0);
							try {
								const res = await lionUserManager.authenticationManager.post(
									`/meClaim/admin/editFuelGrade`,
									{ ...data, status: 1 }
								);

								if (res.status == 200) {
									message.success("Done");
									super.onDone(data);
									super.clearItem();
								}
							} catch (err) {
								message.error(texts.DEFAULT_ERROR_MESSAGE);
							} finally {
								_hide();
							}
						}}
						key='me-fuel-grade-edit'
						mode={MasterDataFormMode.EDIT}
					/>
				) : (
					<MeFuelGradeFormWithRepo
						initialValue={{
							name: "",
							id: "",
							fuelType: { name: "", id: "", },
							unitPrice: 0 
						}}
						validators={{
							check_duplicate: this._validateDuplicates.bind(this)
						}}
						onDone={async (data: MeFuelGrade) => {
							let _hide = message.loading("Action in progress..", 0);
							try {
								const res = await lionUserManager.authenticationManager.post(
									"/meClaim/admin/createFuelGrade",
									{ ...data, status: 1 }
								);

								if (res.status == 201) {
									message.success("Done");
									super.onDone(data);
								}
							} catch (err) {
								message.error(texts.DEFAULT_ERROR_MESSAGE);
							} finally {
								_hide();
							}
						}}
						key='me-fuel-grade-create'
						mode={MasterDataFormMode.CREATE}
					/>
				)}
				<Table dataSource={this.props.sourceData}>
					<Table.Column
						title='No'
						key='index'
						render={(value, item, index) => index + 1}
					/>
					<Table.Column title='Category Name ' dataIndex='name' />
					<Table.Column title='Unit Price' dataIndex='unitPrice' key='unitPrice' />
					<Table.Column
						title='Fuel Type'
						dataIndex='fuelType'
						render={(value: MeFuelType, item, index) => value && value.name}
					/>
					<Table.Column
						title='Action'
						key='action'
						render={(value, item: MeFuelGrade, index) => (
							<span>
								{/* <DeleteOutlined style={{ color: "#de2f38" }} /> */}
								<EditOutlined
									style={{ color: "#de2f38", marginLeft: 10 }}
									onClick={() => this.setItem(item)}
								/>
							</span>
						)}
					/>
				</Table>
			</>
		);
	}
}

class MeFuelGradeForm extends MasterDataForm<MeFuelGrade, any> {
	async _onDone() {
		if (this.formRef.current && (await this.validate())) {
			const { MeClaimRepo } = this.props as any;
			const fTypeId = this.formRef.current.getFieldValue("ftype");
			super.onDone({
				id: this.props.initialValue.id,
				name: this.formRef.current.getFieldValue("name"),
				unitPrice: this.formRef.current.getFieldValue("price"),
				fuelType: MeClaimRepo.fuelTypes.find(
					(value: MeFuelType, i: number, s: any) => value.id == fTypeId
				)
			});
		}
	}

	componentDidMount() {
		console.log({ MeFuelGradeForm: this.props });
	}

	render() {
		const { MeClaimRepo } = this.props as any;
		return (
			<>
				{this.props.mode == MasterDataFormMode.EDIT && (
					<Alert
						message='Currently in Edit state.'
						type='info'
						closeText='Exit Now'
						onClose={() => super.changeMode(MasterDataFormMode.CREATE)}
					/>
				)}
				<Form
					// {...layout}
					ref={this.formRef}
					initialValues={{
						ftype: this.props.initialValue.fuelType.id,
						name: this.props.initialValue.name,
						price: this.props.initialValue.unitPrice,
					}}
					onFinish={this._onDone.bind(this)}>
					<Form.Item
						name='ftype'
						label='Fuel Type'
						rules={[
							{
								required: true,
								message: "Fuel type is required"
							}
						]}>
						<Select allowClear disabled={MeClaimRepo.fuelTypes.length < 1}>
							{MeClaimRepo.fuelTypes.map((type: MeFuelType) => (
								<Select.Option value={type.id}>{type.name}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name='name'
						label='Fuel Grade'
						rules={[
							{
								validator: async (rule, value) => {
									if (this.props.validators["check_duplicate"])
										await this.props.validators["check_duplicate"](rule, value);
								}
							},
							{
								required: true,
								message: "Fuel grade is required"
							}
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name='price'
						label='Unit Price'
						rules={[
							{
								required: true,
								message: "Unit price is required"
							}
						]}>
						<InputNumber min={0} />
					</Form.Item>
					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</>
		);
	}
}

const MeFuelGradeFormWithRepo = connect(
	(state: any) => state,
	MeClaimRepoActions
)(MeFuelGradeForm);
