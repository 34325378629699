import React from "react";
import { MasterdataEditTab } from "../../../components/MasterDataTab";
import { MasterDataFormMode, MasterDataForm } from "../../../components/Form";
import {
	Button,
	Input,
	Form,
	Select,
	Table,
	Alert,
	message,
	InputNumber
} from "antd";
import { lionUserManager } from "../../../repos/UserRepo";
import { texts } from "../../../texts.config";
import { EditOutlined } from "@ant-design/icons";
import {
	MeExtraExpenseRule,
	MeUser,
	MeClaimRepoActions
} from "../../../repos/MeClaimRepo";
import { connect } from "react-redux";

export class MeExtraExpenseRuleTab extends MasterdataEditTab<
	MeExtraExpenseRule
> {
	_onModeChange(mode: MasterDataFormMode) {
		super.clearItem();
	}

	render() {
		return (
			<>
				{this.state.item ? (
					<MeExtraExpenseRuleFormWithRepo
						initialValue={this.state.item}
						onModeChange={this._onModeChange.bind(this)}
						onDone={async (data: MeExtraExpenseRule) => {
							let _hide = message.loading("Action in progress..", 0);
							try {
								const res = await lionUserManager.authenticationManager.post(
									`/meClaim/admin/editExtraExpenseRules`,
									{ ...data, status: 1, userRole: data.userRole.id }
								);

								if (res.status == 200) {
									message.success("Done");
									super.onDone(data);
									super.clearItem();
								}
							} catch (err) {
								message.error(texts.DEFAULT_ERROR_MESSAGE);
							} finally {
								_hide();
							}
						}}
						key='me-extra-expense-rule-edit'
						mode={MasterDataFormMode.EDIT}
					/>
				) : (
					<MeExtraExpenseRuleFormWithRepo
						initialValue={{
							area: "",
							id: "",
							amount: 0,
							userRole: { id: "0", name: "" },
							term: "",
							expenseType: { name: "", id: "" }
						}}
						onDone={async (data: MeExtraExpenseRule) => {
							let _hide = message.loading("Action in progress..", 0);
							try {
								const res = await lionUserManager.authenticationManager.post(
									"/meClaim/admin/createExtraExpenseRules",
									{ ...data, status: 1, userRole: data.userRole.id }
								);

								if (res.status == 201) {
									message.success("Done");
									super.onDone(data);
								}
							} catch (err) {
								message.error(texts.DEFAULT_ERROR_MESSAGE);
							} finally {
								_hide();
							}
						}}
						key='me-extra-expense-rule-create'
						mode={MasterDataFormMode.CREATE}
					/>
				)}
				<Table dataSource={this.props.sourceData}>
					<Table.Column
						title='No'
						key='index'
						render={(value, item, index) => index + 1}
					/>
					<Table.Column title='Amount' dataIndex='amount' />
					<Table.Column title='Area' dataIndex='area' />
					<Table.Column title='Term' dataIndex='term' />
					<Table.Column
						title='Role'
						key='role'
						render={(value, item: MeExtraExpenseRule, index) => (
							<span>{item.id}</span>
						)}
					/>
					<Table.Column
						title='Action'
						key='action'
						render={(value, item: MeExtraExpenseRule, index) => (
							<span>
								{/* <DeleteOutlined style={{ color: "#de2f38" }} /> */}
								<EditOutlined
									style={{ color: "#de2f38", marginLeft: 10 }}
									onClick={() => this.setItem(item)}
								/>
							</span>
						)}
					/>
				</Table>
			</>
		);
	}
}

class MeExtraExpenseRuleForm extends MasterDataForm<MeExtraExpenseRule, any> {
	async _onDone() {
		if (this.formRef.current && (await this.validate()))
			super.onDone({
				id: this.props.initialValue.id,
				area: this.formRef.current.getFieldValue("area"),
				// userRole: this.formRef.current.getFieldValue("name"),
				userRole: { id: "19", name: "" },
				amount: this.formRef.current.getFieldValue("amount"),
				term: this.formRef.current.getFieldValue("term"),
				expenseType: { name: "", id: "4" }
			});
	}

	render() {
		const { MeClaimRepo } = this.props as any;

		return (
			<>
				{this.props.mode == MasterDataFormMode.EDIT && (
					<Alert
						message='Currently in Edit state.'
						type='info'
						closeText='Exit Now'
						onClose={() => super.changeMode(MasterDataFormMode.CREATE)}
					/>
				)}
				<Form
					// {...layout}
					ref={this.formRef}
					initialValues={{
						// name: this.props.initialValue.name

						area: this.props.initialValue.area,
						amount: this.props.initialValue.amount,
						term: this.props.initialValue.term
					}}
					onFinish={this._onDone.bind(this)}>
					<Form.Item
						name='role'
						label='User Role'
						rules={
							[
								{
									required: true,
									message: "User role is require"
								}
							]
						}>
						<Select allowClear disabled={MeClaimRepo.users.length < 1}>
							{MeClaimRepo.users.map((type: MeUser, i: any) => (
								<Select.Option value={type.id}>{type.name}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name='term'
						label='Term'
						rules={[
							{
								required: true,
								message: "Term is required"
							}
						]}>
						<Select allowClear>
							<Select.Option value='DAY'>DAY</Select.Option>
							<Select.Option value='MONTH'>MONTH</Select.Option>
							<Select.Option value='YEAR'>YEAR</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						name='amount'
						label='Amount'
						rules={[
							{
								required: true,
								message: "Amount is required"
							}
						]}>
						<InputNumber min={0} />
					</Form.Item>
					<Form.Item
						name='area'
						label='Area'
						rules={[
							{
								required: true,
								message: "Area is required"
							}
						]}>
						<Select allowClear>
							<Select.Option value='ANURADHAPURA'>ANURADHAPURA</Select.Option>
							<Select.Option value='BATTICALOA'>BATTICALOA</Select.Option>
							<Select.Option value='TRINCOMALEE'>TRINCOMALEE</Select.Option>
							<Select.Option value='OTHER'>OTHER</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</>
		);
	}
}

export const MeExtraExpenseRuleFormWithRepo = connect(
	(state: any) => state,
	MeClaimRepoActions
)(MeExtraExpenseRuleForm);
