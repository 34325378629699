export class RouteConfig{
    static root = '/'

    static PROTECTED = '/protected'
    static PROTECTED_ROOT = '/protected/'
    static PROTECTED_ARTWORK = '/protected/artwork'
    static PROTECTED_MEDICAL_CLAIM = '/protected/medical-claim'
    static PROTECTED_CLUB_MEMBERSHIP_CLAIM = '/protected/club-membership-claim'
    static PROTECTED_FUEL_CLAIM = '/protected/fuel-claim' 
    static PROTECTED_PETTY_CASH_CLAIM = '/protected/petty-cash-claim'  
	static PROTECTED_ME_CLAIM = '/protected/me-claim'  
	static PROTECTED_STAFF_LOAN = '/protected/staff-loan'
	static PROTECTED_USER_MANAGEMENT = '/protected/user-management' 
	static PROTECTED_USER_VIEW = '/protected/user-view' 
	static PROTECTED_ROLE_MANAGEMENT = '/protected/role-management' 
    static PROTECTED_DEPARTMENT_MANAGEMENT = '/protected/department-management'
    

    static LOGIN = '/'
}

//this applies to breadcrumbs
export const rootComponent = "Home"
export const rootIcon = "home"

